import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Hotjar from '@hotjar/browser';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'MainContainer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../containers/MainContainer.vue'),
    // Fetch the current user obj before entering the main container,
    // the obj is used as a base for checking available products.
    // This ensures that the data will get populated in store before the main container renders
    async beforeEnter(to, from, next) {
      try {
        await store.dispatch('getUser');
      } catch (e) {
        console.error(e);
      }
      next();
    },
    children: [
      {
        path: 'product',
        name: 'Evaluator',
        component: () => import('../containers/Evaluator'),
        props: true,
        children: [
          {
            path: ':productName',
            name: 'ProductView',
            component: () => import('../containers/Evaluator/ProductView'),
            props: true,
            meta: {
              breadcrumb: ({ productName, productText }) => [
                {
                  text: productText,
                  to: {
                    name: 'ProductView',
                    params: {
                      productName,
                    },
                  },
                },
              ],
            },
          },
          {
            path: ':productName/add',
            name: 'AddNewProduct',
            component: () => import('../containers/SelfService/AddNewProduct'),
            props: true,
          },
          {
            // regex to exclude other products written directly in the URL
            path: ':productName(bq_advisory_wealth)/packages',
            name: 'Packages',
            component: () => import('../containers/SelfService/Packages'),
            props: true,
            meta: {
              breadcrumb: () => ['Packages'],
            },
          },
          {
            path: ':productName/sign-up-success',
            name: 'CrossSignUpSuccess',
            component: () => import('../containers/SelfService/CrossSignUpSuccess'),
            props: true,
          },
          {
            path: ':productName/teams',
            name: 'TeamsView',
            component: () => import('../containers/Evaluator/TeamsView'),
            props: true,
            meta: {
              breadcrumb: ({ productText, productName, teamsText }) => [
                {
                  text: productText,
                  to: {
                    name: 'ProductView',
                    params: {
                      productName,
                    },
                  },
                },
                {
                  text: teamsText,
                  to: {
                    name: 'TeamsView',
                  },
                },
              ],
            },
          },
          {
            path: ':productName/teams/comparison',
            name: 'TeamComparison',
            component: () => import('../containers/Dashboards/Teams/Comparison'),
            props: true,
            meta: {
              breadcrumb: ({ productText, productName, teamsText, teamComparisonText }) => [
                {
                  text: productText,
                  to: {
                    name: 'ProductView',
                    params: {
                      productName,
                    },
                  },
                },
                {
                  text: teamsText,
                  to: {
                    name: 'TeamsView',
                  },
                },
                {
                  text: teamComparisonText,
                },
              ],
            },
          },
          {
            path: ':productName/teams/:teamId/dashboard',
            name: 'TeamsDashboard',
            component: () => import('../containers/Dashboards/Teams'),
            props: true,
            meta: {
              breadcrumb: ({ productText, productName, teamsText, teamName }) => [
                {
                  text: productText,
                  to: {
                    name: 'ProductView',
                    params: {
                      productName,
                    },
                  },
                },
                {
                  text: teamsText,
                  to: {
                    name: 'TeamsView',
                  },
                },
                {
                  text: teamName,
                },
              ],
            },
          },
          {
            path: ':productName/comparison',
            name: 'Comparison',
            component: () => import('../containers/Dashboards/Comparison'),
            props: true,
            meta: {
              breadcrumb: ({ productName, productText, comparisonText }) => [
                {
                  text: productText,
                  to: {
                    name: 'ProductView',
                    params: {
                      productName,
                    },
                  },
                },
                {
                  text: comparisonText,
                },
              ],
            },
          },
          {
            path: ':productName/weights',
            name: 'WeightProfilesView',
            component: () => import('../containers/Evaluator/WeightProfilesView'),
            props: true,
            meta: {
              breadcrumb: ({ productText, productName, weightsText }) => [
                {
                  text: productText,
                  to: {
                    name: 'ProductView',
                    params: {
                      productName,
                    },
                  },
                },
                {
                  text: weightsText,
                },
              ],
            },
          },
          {
            path: ':productName/client',
            name: 'ClientCreate',
            component: () => import('../containers/Actions/ClientCreate'),
          },
          {
            path: ':productName/client/:clientToken',
            name: 'ClientView',
            component: () => import('../containers/Evaluator/ClientView'),
            props: true,
          },
          {
            path: ':productName/client/:clientToken/interaction/:interactionInstanceToken',
            name: 'InteractionActions',
            component: () => import('../containers/Actions/InteractionActions'),
            props: true,
          },
          {
            name: 'DashboardContainer',
            path: ':productName/client/:clientToken/dashboard/:interactionInstanceToken',
            props: true,
            component: () => import('../containers/Dashboards'),
            meta: {
              ext: true,
              breadcrumb: ({
                productName,
                productText,
                clientToken,
                clientText,
                resultText,
                interactionInstanceToken,
              }) => [
                {
                  text: productText,
                  to: {
                    name: 'ProductView',
                    params: {
                      productName,
                    },
                  },
                },
                {
                  text: clientText,
                  to: {
                    name: 'ClientView',
                    params: {
                      clientToken,
                    },
                  },
                },
                {
                  text: resultText,
                  href: `/product/${productName}/client/${clientToken}/dashboard/${interactionInstanceToken}`,
                },
              ],
            },
          },
          {
            // Data Export
            path: '/product/:productName/export',
            name: 'ProductDataExport',
            meta: {
              breadcrumb: ({ productName, productText }) => [
                {
                  text: productText,
                  to: {
                    name: 'ProductView',
                    params: {
                      productName,
                    },
                  },
                },
                {
                  text: 'Data Export',
                },
              ],
            },
            component: () => import('../containers/DataExport'),
          },
          {
            path: ':productName/checkout_success/:userReference',
            name: 'CheckoutSuccess',
            component: () => import('../containers/SelfService/CheckoutSuccess'),
            props: true,
          },
        ],
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import('../containers/Settings'),
        meta: {
          breadcrumb: () => ['Settings'],
        },
        children: [
          {
            path: 'password',
            name: 'ChangePassword',
            component: () => import('../containers/Settings/SettingsChangePassword'),
            props: true,
            meta: {
              breadcrumb: () => [
                {
                  to: {
                    name: 'Password',
                  },
                },
              ],
            },
          },
          {
            path: 'language',
            name: 'ChangeLanguage',
            component: () => import('../containers/Settings/SettingsChangeLanguage'),
            props: true,
            meta: {
              breadcrumb: () => [
                {
                  to: {
                    name: 'Language',
                  },
                },
              ],
            },
          },
        ],
      },
      {
        path: 'icons',
        name: 'Icons',
        component: () => import('../containers/Internal/Icons'),
      },
      {
        path: 'translations',
        name: 'Translations',
        component: () => import('../containers/Internal/Translations'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    props: true,
    component: () => import('../containers/Login'),
  },
  {
    path: '/to_signup',
    name: 'RedirectToRegistration',
    props: true,
    component: () => import('../containers/Login/RedirectToRegistration'),
  },
  {
    path: '/signup/ext',
    name: 'RegistrationExternal',
    props: true,
    component: () => import('../containers/Registration/RegistrationFormExternal'),
    meta: { type: 'bqa' },
  },
  {
    path: '/signup/success',
    name: 'RegistrationConfirmation',
    props: true,
    component: () => import('../containers/Registration/RegistrationConfirmation'),
  },
  {
    path: '/signup/benchmark/ext',
    name: 'RegistrationBQPBenchmarkFormExternal',
    props: true,
    component: () => import('../containers/Registration/RegistrationFormExternal'),
    meta: { type: 'benchmark' },
  },
  {
    path: '/verify/:emailVerificationToken',
    name: 'EmailVerification',
    props: true,
    component: () => import('../containers/Registration/EmailVerification'),
  },
  {
    // BQP Report
    name: 'performanceReport',
    path: '/performance/report/:interactionInstanceToken',
    props: true,
    meta: {
      ext: true,
      authDisabled: true,
    },
    component: () => import('../containers/Reports/BQP'),
  },
  {
    // BQA Report
    name: 'bqaReport',
    path: '/bqa/report/:interactionInstanceToken',
    props: true,
    meta: {
      ext: true,
      authDisabled: true,
    },
    component: () => import('../containers/Reports/BQA'),
  },
  {
    // BQA Recommendations Report
    name: 'bqaRecommendationsReport',
    path: '/bqaRecommendations/report/:interactionInstanceToken',
    props: true,
    meta: {
      ext: true,
      authDisabled: true,
    },
    component: () => import('../containers/Reports/BQA/BQARecommendations'),
  },
  {
    // BQP Certificate
    name: 'bqpCertificate',
    path: '/bqp/certificate/:interactionInstanceToken',
    props: true,
    meta: {
      ext: true,
      authDisabled: true,
    },
    component: () => import('../containers/Certificate/BQP'),
  },
  {
    // Password Recovery
    path: '/password/recovery',
    name: 'PasswordRecovery',
    props: true,
    meta: {
      ext: true,
    },
    component: () => import('../containers/PasswordRecovery'),
  },
  {
    // Password Reset
    path: '/password/reset/complete',
    name: 'PasswordReset',
    props: true,
    meta: {
      ext: true,
    },
    component: () => import('../containers/PasswordReset'),
  },
  {
    path: '/report/:reportDownloadToken/download',
    name: 'ReportDownload',
    props: true,
    meta: {
      ext: true,
    },
    component: () => import('../containers/ReportDownload.vue'),
  },
  {
    path: '/demo/password-set/:instanceToken',
    name: 'PasswordSetDemo',
    props: true,
    meta: {
      ext: true,
    },
    component: () => import('../containers/PasswordSetDemo'),
  },
  {
    // Public Certificate Verification Page
    name: 'CertificateVerification',
    path: '/verify/certificate/:certificateToken',
    props: true,
    meta: {
      ext: true,
      authDisabled: true,
    },
    component: () => import('../containers/Certificate/CertificateVerification'),
  },
  {
    path: '/*',
    name: 'PageNotFound',
    component: () => import('../containers/PageNotFoundContainer.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

function hasQueryParams(obj) {
  return !!Object.keys(obj).length;
}

router.beforeEach((to, from, next) => {
  Hotjar.stateChange(to.path);

  // nav guard to preserve the query parameters used for fetching data in ProductView for customer-table
  // when navigatin away from it, and reapply them when returning to the view
  const biRoute = 'ProductView';
  const isBiRoute = to.name === biRoute;
  const isSameProduct = to.params.productName === from.params.productName;

  if (isSameProduct) {
    if (isBiRoute) {
      // ensure there are no query params already and we aren't navigating inside the biRoute itself
      if (to.name !== from.name && !hasQueryParams(to.query)) {
        if (from.meta.query && hasQueryParams(from.meta.query)) {
          // apply query parameters
          next({ ...to, query: from.meta.query });
          return;
        }
      }
    } else if ((from.query && hasQueryParams(from.query)) || (from.meta.query && hasQueryParams(from.meta.query))) {
      // preserve query parameters
      to.meta.query = hasQueryParams(from.query) ? from.query : from.meta.query;
    } else {
      // HACK
      // sanitize query parameters
      delete to.meta.query;
    }
  }
  next();
});

// Navigating to ProductView with query parameters can lead to some weird redirect errors. Here, we supress it since it works as expected.
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      console.log('Ignoring NavigationFailure error', err);
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

export default router;
