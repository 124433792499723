/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */

import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  DE: {},
  EN: {},
};

const i18n = new VueI18n({
  locale: 'DE', // set locale
  fallbackLocale: 'EN', // set fallback locale
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  messages, // set locale messages
});

function translation(type, product, key, subType) {
  let translationKey = `${type}_${key}`;
  if (product !== undefined) {
    translationKey = `${type}_${product}_${key}`;
  }
  if (subType !== undefined) {
    translationKey = `${translationKey}_${subType}`;
  }

  return function (values, locale) {
    if (values === undefined) {
      if (locale === undefined) {
        return i18n.t(translationKey);
      }
      return i18n.t(translationKey, locale);
    }
    if (locale === undefined) {
      return i18n.t(translationKey, values);
    }
    return i18n.t(translationKey, locale, values);
  };
}

/**
 *
 * product related BQUI translation keys
 * we have to be able to differentiate by product for these
 *
 * @param {*} product - product name
 * @returns - object with translation functions for each key based on passed product
 */
export const BQUI_BASE_PRODUCT = (product) => ({
  TITLE: translation('BQUI', product, 'TITLE'),
  MENU_NAV: {
    HELP_BUTTON_TEXT: translation('BQUI', product, 'MENU_NAV', 'HELP_BUTTON_TEXT'),
    HELP_LINK: translation('BQUI', product, 'MENU_NAV', 'HELP_LINK'),
  },
  NOTIFICATIONS: {
    NOTIFICATION_INTERACTION_DELETE_SUCCESS: translation(
      'BQUI',
      product,
      'NOTIFICATIONS',
      'NOTIFICATION_INTERACTION_DELETE_SUCCESS'
    ),
    NOTIFICATION_INTERACTION_DELETE_ERROR: translation(
      'BQUI',
      product,
      'NOTIFICATIONS',
      'NOTIFICATION_INTERACTION_DELETE_ERROR'
    ),
    NOTIFICATION_CLIENT_UPDATE_SUCCESS: translation(
      'BQUI',
      product,
      'NOTIFICATIONS',
      'NOTIFICATION_CLIENT_UPDATE_SUCCESS'
    ),
    NOTIFICATION_CLIENT_UPDATE_ERROR: translation('BQUI', product, 'NOTIFICATIONS', 'NOTIFICATION_CLIENT_UPDATE_ERROR'),
    NOTIFICATION_CLIENT_DELETE_SUCCESS: translation(
      'BQUI',
      product,
      'NOTIFICATIONS',
      'NOTIFICATION_CLIENT_DELETE_SUCCESS'
    ),
    NOTIFICATION_CLIENT_DELETE_ERROR: translation('BQUI', product, 'NOTIFICATIONS', 'NOTIFICATION_CLIENT_DELETE_ERROR'),
    NOTIFICATION_DOWNLOAD_REPORT_SUCCESS: translation(
      'BQUI',
      product,
      'NOTIFICATIONS',
      'NOTIFICATION_DOWNLOAD_REPORT_SUCCESS'
    ),
    NOTIFICATION_DOWNLOAD_REPORT_ERROR: translation(
      'BQUI',
      product,
      'NOTIFICATIONS',
      'NOTIFICATION_DOWNLOAD_REPORT_ERROR'
    ),
    DOWNLOAD_CERTIFICATE_SUCCESS: translation('BQUI', product, 'NOTIFICATIONS', 'DOWNLOAD_CERTIFICATE_SUCCESS'),
    DOWNLOAD_CERTIFICATE_ERROR: translation('BQUI', product, 'NOTIFICATIONS', 'DOWNLOAD_CERTIFICATE_ERROR'),
    DOWNLOAD_BADGE_SUCCESS: translation('BQUI', product, 'NOTIFICATIONS', 'DOWNLOAD_BADGE_SUCCESS'),
    DOWNLOAD_BADGE_ERROR: translation('BQUI', product, 'NOTIFICATIONS', 'DOWNLOAD_BADGE_ERROR'),
  },
  COMPONENT_MODAL_WITH_SECONDARY_CONFIRMATION: {
    VALIDATION_ERROR_SECURITY_CODE_NOT_MATCHING: translation(
      'BQUI',
      product,
      'COMPONENT_MODAL_WITH_SECONDARY_CONFIRMATION',
      'VALIDATION_ERROR_SECURITY_CODE_NOT_MATCHING'
    ),
  },
  COMPONENT_DATETIME_PICKER: {
    VALIDATION_ERROR_DATE_REQUIRED: translation(
      'BQUI',
      product,
      'COMPONENT_DATETIME_PICKER',
      'VALIDATION_ERROR_DATE_REQUIRED'
    ),
    VALIDATION_ERROR_TIME_REQUIRED: translation(
      'BQUI',
      product,
      'COMPONENT_DATETIME_PICKER',
      'VALIDATION_ERROR_TIME_REQUIRED'
    ),
  },
  COMPONENT_USER_PROFILE: {
    CONTAINER_TITLE: translation('BQUI', product, 'COMPONENT_USER_PROFILE', 'CONTAINER_TITLE'),
    CLIENT_GROUP_CHECKBOX_LABEL: translation('BQUI', product, 'COMPONENT_USER_PROFILE', 'CLIENT_GROUP_CHECKBOX_LABEL'),
  },
  COMPONENT_INTERACTION_ACTIONS: {
    TITLE: translation('BQUI', product, 'COMPONENT_INTERACTION_ACTIONS', 'TITLE'),
    EMAIL_TITLE: translation('BQUI', product, 'COMPONENT_INTERACTION_ACTIONS', 'EMAIL_TITLE'),
    LINK_TEXT: translation('BQUI', product, 'COMPONENT_INTERACTION_ACTIONS', 'LINK_TEXT'),
    ACTIONS_HEADER: translation('BQUI', product, 'COMPONENT_INTERACTION_ACTIONS', 'ACTIONS_HEADER'),
    BUTTON_COPY_INTERACTION_LINK: translation(
      'BQUI',
      product,
      'COMPONENT_INTERACTION_ACTIONS',
      'BUTTON_COPY_INTERACTION_LINK'
    ),
    BUTTON_OPEN_EMAIL_CLIENT: translation('BQUI', product, 'COMPONENT_INTERACTION_ACTIONS', 'BUTTON_OPEN_EMAIL_CLIENT'),
    BUTTON_SEND_AUTO_INVITE: translation('BQUI', product, 'COMPONENT_INTERACTION_ACTIONS', 'BUTTON_SEND_AUTO_INVITE'),
    BUTTONDESCRIPTION_SEND_AUTO_INVITE: translation(
      'BQUI',
      product,
      'COMPONENT_INTERACTION_ACTIONS',
      'BUTTONDESCRIPTION_SEND_AUTO_INVITE'
    ),
    BUTTONDESCRIPTION_COPY_INTERACTION_LINK: translation(
      'BQUI',
      product,
      'COMPONENT_INTERACTION_ACTIONS',
      'BUTTONDESCRIPTION_COPY_INTERACTION_LINK'
    ),
    BUTTONDESCRIPTION_OPEN_EMAIL_CLIENT: translation(
      'BQUI',
      product,
      'COMPONENT_INTERACTION_ACTIONS',
      'BUTTONDESCRIPTION_OPEN_EMAIL_CLIENT'
    ),
    ADVANCED_OPTIONS_TOGGLE_TITLE: translation(
      'BQUI',
      product,
      'COMPONENT_INTERACTION_ACTIONS',
      'ADVANCED_OPTIONS_TOGGLE_TITLE'
    ),
    ADVANCED_OPTIONS_DESCRIPTION_TITLE: translation(
      'BQUI',
      product,
      'COMPONENT_INTERACTION_ACTIONS',
      'ADVANCED_OPTIONS_DESCRIPTION_TITLE'
    ),
    AUTO_REMINDER_ON: translation('BQUI', product, 'COMPONENT_INTERACTION_ACTIONS', 'AUTO_REMINDER_ON'),
    AUTO_REMINDER_OFF: translation('BQUI', product, 'COMPONENT_INTERACTION_ACTIONS', 'AUTO_REMINDER_OFF'),
    AUTO_REPORT_ON: translation('BQUI', product, 'COMPONENT_INTERACTION_ACTIONS', 'AUTO_REPORT_ON'),
    AUTO_REPORT_OFF: translation('BQUI', product, 'COMPONENT_INTERACTION_ACTIONS', 'AUTO_REPORT_OFF'),
    BUTTON_DELETE_INTERACTION: translation(
      'BQUI',
      product,
      'COMPONENT_INTERACTION_ACTIONS',
      'BUTTON_DELETE_INTERACTION'
    ),
    BUTTONDESCRIPTION_DELETE_INTERACTION: translation(
      'BQUI',
      product,
      'COMPONENT_INTERACTION_ACTIONS',
      'BUTTONDESCRIPTION_DELETE_INTERACTION'
    ),
    EMAIL_CLIENT_SUBJECT: translation('BQUI', product, 'COMPONENT_INTERACTION_ACTIONS', 'EMAIL_CLIENT_SUBJECT'),
    EMAIL_CLIENT_CONTENT: translation('BQUI', product, 'COMPONENT_INTERACTION_ACTIONS', 'EMAIL_CLIENT_CONTENT'),
    BUTTON_BACK: translation('BQUI', product, 'COMPONENT_INTERACTION_ACTIONS', 'BUTTON_BACK'),
    NOTIFICATION_COPY_INTERACTION_LINK_SUCCESS: translation(
      'BQUI',
      product,
      'COMPONENT_INTERACTION_ACTIONS',
      'NOTIFICATION_COPY_INTERACTION_LINK_SUCCESS'
    ),
    NOTIFICATION_COPY_INTERACTION_LINK_ERROR: translation(
      'BQUI',
      product,
      'COMPONENT_INTERACTION_ACTIONS',
      'NOTIFICATION_COPY_INTERACTION_LINK_ERROR'
    ),
  },
  COMPONENT_CLIENT_TABLE: {
    BUTTON_ADD_TO_COMPARISON: translation('BQUI', product, 'COMPONENT_CLIENT_TABLE', 'BUTTON_ADD_TO_COMPARISON'),
    BUTTON_REMOVE_FROM_COMPARISON: translation(
      'BQUI',
      product,
      'COMPONENT_CLIENT_TABLE',
      'BUTTON_REMOVE_FROM_COMPARISON'
    ),
    FILTER_LABEL_SEARCH: translation('BQUI', product, 'COMPONENT_CLIENT_TABLE', 'FILTER_LABEL_SEARCH'),
    FILTER_LABEL_CLIENT_GROUP: translation('BQUI', product, 'COMPONENT_CLIENT_TABLE', 'FILTER_LABEL_CLIENT_GROUP'),
    FILTER_LABEL_CATEGORY: translation('BQUI', product, 'COMPONENT_CLIENT_TABLE', 'FILTER_LABEL_CATEGORY'),
  },
  COMPONENT_COMPARISON_WIDGET: {
    TITLE: translation('BQUI', product, 'COMPONENT_COMPARISON_WIDGET', 'TITLE'),
    EMPTY_COMPARISON_MESSAGE: translation('BQUI', product, 'COMPONENT_COMPARISON_WIDGET', 'EMPTY_COMPARISON_MESSAGE'),
    BUTTON_VIEW_COMPARISON: translation('BQUI', product, 'COMPONENT_COMPARISON_WIDGET', 'BUTTON_VIEW_COMPARISON'),
    ADD_MORE_USERS_MESSAGE: translation('BQUI', product, 'COMPONENT_COMPARISON_WIDGET', 'ADD_MORE_USERS_MESSAGE'),
  },
  COMPONENT_TEAMS_WIDGET: {
    TITLE: translation('BQUI', product, 'COMPONENT_TEAMS_WIDGET', 'TITLE'),
    CURRENT_TEAMS_TITLE: translation('BQUI', product, 'COMPONENT_TEAMS_WIDGET', 'CURRENT_TEAMS_TITLE'),
    INFO_TEXT: translation('BQUI', product, 'COMPONENT_TEAMS_WIDGET', 'INFO_TEXT'),
    BUTTON_MANAGE_TEAMS: translation('BQUI', product, 'COMPONENT_TEAMS_WIDGET', 'BUTTON_MANAGE_TEAMS'),
    BUTTON_ADD_TEAMS: translation('BQUI', product, 'COMPONENT_TEAMS_WIDGET', 'BUTTON_ADD_TEAMS'),
  },
  COMPONENT_WEIGHTS_WIDGET: {
    TITLE: translation('BQUI', product, 'COMPONENT_WEIGHTS_WIDGET', 'TITLE'),
    INFO_TEXT: translation('BQUI', product, 'COMPONENT_WEIGHTS_WIDGET', 'INFO_TEXT'),
    BUTTON_MANAGE_WEIGHTS: translation('BQUI', product, 'COMPONENT_WEIGHTS_WIDGET', 'BUTTON_MANAGE_WEIGHTS'),
  },
  COMPONENT_WEIGHTS_VIEW: {
    SELECT_PROFILE: translation('BQUI', product, 'COMPONENT_WEIGHTS_VIEW', 'SELECT_PROFILE'),
  },
  COMPONENT_CREATE_CLIENT: {
    TITLE: translation('BQUI', product, 'COMPONENT_CREATE_CLIENT', 'TITLE'),
    BUTTON_CREATE: translation('BQUI', product, 'COMPONENT_CREATE_CLIENT', 'BUTTON_CREATE'),
    CLIENT_CREATED_SUCCESS: translation('BQUI', product, 'COMPONENT_CREATE_CLIENT', 'CLIENT_CREATED_SUCCESS'),
    CLIENT_CREATED_ERROR: translation('BQUI', product, 'COMPONENT_CREATE_CLIENT', 'CLIENT_CREATED_ERROR'),
    DUPLICATE_CLIENT_ERROR: translation('BQUI', product, 'COMPONENT_CREATE_CLIENT', 'DUPLICATE_CLIENT_ERROR'),
  },
  COMPONENT_TEAMS_TABLE: {
    TABLE_NO_DATA_MESSAGE: translation('BQUI', product, 'COMPONENT_TEAMS_TABLE', 'TABLE_NO_DATA_MESSAGE'),
  },
  FILE_DOWNLOAD: {
    BQA_BANK_PRODUCT_NAME: translation('BQUI', product, 'FILE_DOWNLOAD', 'BQA_BANK_PRODUCT_NAME'),
    BQA_WEALTH_PRODUCT_NAME: translation('BQUI', product, 'FILE_DOWNLOAD', 'BQA_WEALTH_PRODUCT_NAME'),
    BQR_PRODUCT_NAME: translation('BQUI', product, 'FILE_DOWNLOAD', 'BQR_PRODUCT_NAME'),
    BQS_PRODUCT_NAME: translation('BQUI', product, 'FILE_DOWNLOAD', 'BQS_PRODUCT_NAME'),
    BQP_PRODUCT_NAME: translation('BQUI', product, 'FILE_DOWNLOAD', 'BQP_PRODUCT_NAME'),
  },
  TEAMS: {
    TEAM_NOTIFICATION_CREATE_SUCCESS: translation('BQUI', product, 'TEAMS', 'TEAM_NOTIFICATION_CREATE_SUCCESS'),
    TEAM_NOTIFICATION_CREATE_ERROR: translation('BQUI', product, 'TEAMS', 'TEAM_NOTIFICATION_CREATE_ERROR'),
    TEAM_NOTIFICATION_DELETE_SUCCESS: translation('BQUI', product, 'TEAMS', 'TEAM_NOTIFICATION_DELETE_SUCCESS'),
    TEAM_NOTIFICATION_DELETE_ERROR: translation('BQUI', product, 'TEAMS', 'TEAM_NOTIFICATION_DELETE_ERROR'),
  },
  PRODUCT_VIEW: {
    CREATE_CLIENT_BUTTON: translation('BQUI', product, 'PRODUCT_VIEW', 'CREATE_CLIENT_BUTTON'),
  },
  AUTO_REPORT_DOWNLOAD: {
    TITLE: translation('BQUI', product, 'AUTO_REPORT_DOWNLOAD', 'TITLE'),
    INSTRUCTION: translation('BQUI', product, 'AUTO_REPORT_DOWNLOAD', 'INSTRUCTION'),
    ERROR_EXPIRED: translation('BQUI', product, 'AUTO_REPORT_DOWNLOAD', 'ERROR_EXPIRED'),
    ERROR_GENERAL: translation('BQUI', product, 'AUTO_REPORT_DOWNLOAD', 'ERROR_GENERAL'),
    BUTTON_DOWNLOAD_REPORT: translation('BQUI', product, 'AUTO_REPORT_DOWNLOAD', 'BUTTON_DOWNLOAD_REPORT'),
    BUTTON_DOWNLOAD_REPORT_AGAIN: translation('BQUI', product, 'AUTO_REPORT_DOWNLOAD', 'BUTTON_DOWNLOAD_REPORT_AGAIN'),
    STATUS_GENERATING_REPORT: translation('BQUI', product, 'AUTO_REPORT_DOWNLOAD', 'STATUS_GENERATING_REPORT'),
  },
  CLIENT_VIEW: {
    INTERACTION_TABLE_TITLE: translation('BQUI', product, 'CLIENT_VIEW', 'INTERACTION_TABLE_TITLE'),
    BUTTON_NO_CREDITS: translation('BQUI', product, 'CLIENT_VIEW', 'BUTTON_NO_CREDITS'),
    BUTTON_VIEW_RESULTS: translation('BQUI', product, 'CLIENT_VIEW', 'BUTTON_VIEW_RESULTS'),
    BUTTON_VIEW_PREV_RESULTS: translation('BQUI', product, 'CLIENT_VIEW', 'BUTTON_VIEW_PREV_RESULTS'),
    BUTTON_VIEW_RESULTS_LOCKED: translation('BQUI', product, 'CLIENT_VIEW', 'BUTTON_VIEW_RESULTS_LOCKED'),
    BUTTON_DOWNLOAD_REPORT: translation('BQUI', product, 'CLIENT_VIEW', 'BUTTON_DOWNLOAD_REPORT'),
    BUTTON_DOWNLOAD_RECOMMENDATIONS: translation('BQUI', product, 'CLIENT_VIEW', 'BUTTON_DOWNLOAD_RECOMMENDATIONS'),
    BUTTON_DOWNLOAD_PREV_REPORT: translation('BQUI', product, 'CLIENT_VIEW', 'BUTTON_DOWNLOAD_PREV_REPORT'),
    BUTTON_DOWNLOAD_REPORT_LOCKED: translation('BQUI', product, 'CLIENT_VIEW', 'BUTTON_DOWNLOAD_REPORT_LOCKED'),
    BUTTON_DOWNLOAD_RECOMMENDATIONS_LOCKED: translation(
      'BQUI',
      product,
      'CLIENT_VIEW',
      'BUTTON_DOWNLOAD_RECOMMENDATIONS_LOCKED'
    ),
    STATUS_GENERATING_REPORT: translation('BQUI', product, 'CLIENT_VIEW', 'STATUS_GENERATING_REPORT'),
    STATUS_GENERATING_RECOMMENDATIONS: translation('BQUI', product, 'CLIENT_VIEW', 'STATUS_GENERATING_RECOMMENDATIONS'),
    DIALOG_DELETE_CLIENT_TITLE: translation('BQUI', product, 'CLIENT_VIEW', 'DIALOG_DELETE_CLIENT_TITLE'),
    DIALOG_DELETE_CLIENT_CONTENT: translation('BQUI', product, 'CLIENT_VIEW', 'DIALOG_DELETE_CLIENT_CONTENT'),
    DIALOG_DELETE_INTERACTION_TITLE: translation('BQUI', product, 'CLIENT_VIEW', 'DIALOG_DELETE_INTERACTION_TITLE'),
    DIALOG_DELETE_INTERACTION_CONTENT: translation('BQUI', product, 'CLIENT_VIEW', 'DIALOG_DELETE_INTERACTION_CONTENT'),
    INTERACTION_TABLE_NO_INTERACTIONS_MESSAGE: translation(
      'BQUI',
      product,
      'CLIENT_VIEW',
      'INTERACTION_TABLE_NO_INTERACTIONS_MESSAGE'
    ),
    INTERACTION_INVITE_BUTTON: translation('BQUI', product, 'CLIENT_VIEW', 'INTERACTION_INVITE_BUTTON'),
    SEND_INTERACTION_INVITE_SUCCESS: translation('BQUI', product, 'CLIENT_VIEW', 'SEND_INTERACTION_INVITE_SUCCESS'),
    SEND_INTERACTION_INVITE_ERROR: translation('BQUI', product, 'CLIENT_VIEW', 'SEND_INTERACTION_INVITE_ERROR'),
    UNLOCK_INTERACTION_MODAL_TITLE: translation('BQUI', product, 'CLIENT_VIEW', 'UNLOCK_INTERACTION_MODAL_TITLE'),
    UNLOCK_INTERACTION_MODAL_TEXT: translation('BQUI', product, 'CLIENT_VIEW', 'UNLOCK_INTERACTION_MODAL_TEXT'),
    UNLOCK_INTERACTION_MODAL_BUTTON_CONFIRM: translation(
      'BQUI',
      product,
      'CLIENT_VIEW',
      'UNLOCK_INTERACTION_MODAL_BUTTON_CONFIRM'
    ),
    UNLOCK_INTERACTION_MODAL_BUTTON_CANCEL: translation(
      'BQUI',
      product,
      'CLIENT_VIEW',
      'UNLOCK_INTERACTION_MODAL_BUTTON_CANCEL'
    ),
    MANAGE_BUTTON: translation('BQUI', product, 'CLIENT_VIEW', 'MANAGE_BUTTON'),
    MANAGE_BUTTON_MOBILE: translation('BQUI', product, 'CLIENT_VIEW', 'MANAGE_BUTTON_MOBILE'),
    EDIT_CLIENT_BUTTON: translation('BQUI', product, 'CLIENT_VIEW', 'EDIT_CLIENT_BUTTON'),
    DELETE_CLIENT_BUTTON: translation('BQUI', product, 'CLIENT_VIEW', 'DELETE_CLIENT_BUTTON'),
    SIDEBAR_HEADER: translation('BQUI', product, 'CLIENT_VIEW', 'SIDEBAR_HEADER'),
    BUTTON_DOWNLOAD_REPORT_MOBILE: translation('BQUI', product, 'CLIENT_VIEW', 'BUTTON_DOWNLOAD_REPORT_MOBILE'),
    BUTTON_DOWNLOAD_RECOMMENDATIONS_MOBILE: translation(
      'BQUI',
      product,
      'CLIENT_VIEW',
      'BUTTON_DOWNLOAD_RECOMMENDATIONS_MOBILE'
    ),
    REMINDER_BUTTON: translation('BQUI', product, 'CLIENT_VIEW', 'REMINDER_BUTTON'),
    RESULT_DATE_HEADER: translation('BQUI', product, 'CLIENT_VIEW', 'RESULT_DATE_HEADER'),
    BUTTON_DOWNLOAD_CERTIFICATE: translation('BQUI', product, 'CLIENT_VIEW', 'BUTTON_DOWNLOAD_CERTIFICATE'),
    BUTTON_DOWNLOAD_CERTIFICATE_MOBILE: translation(
      'BQUI',
      product,
      'CLIENT_VIEW',
      'BUTTON_DOWNLOAD_CERTIFICATE_MOBILE'
    ),
    STATUS_GENERATING_CERTIFICATE: translation('BQUI', product, 'CLIENT_VIEW', 'STATUS_GENERATING_CERTIFICATE'),
  },
  CLIENT_VIEW_MODAL: {
    RESULT_STATUS_OUTDATED_HEADER: translation('BQUI', product, 'CLIENT_VIEW_MODAL', 'RESULT_STATUS_OUTDATED_HEADER'),
    RESULT_STATUS_OUTDATED_TEXT: translation('BQUI', product, 'CLIENT_VIEW_MODAL', 'RESULT_STATUS_OUTDATED_TEXT'),
    RESULT_STATUS_GOOD_HEADER: translation('BQUI', product, 'CLIENT_VIEW_MODAL', 'RESULT_STATUS_GOOD_HEADER'),
    RESULT_STATUS_GOOD_TEXT: translation('BQUI', product, 'CLIENT_VIEW_MODAL', 'RESULT_STATUS_GOOD_TEXT'),
    RESULT_STATUS_NEW_ONGOING_HEADER: translation(
      'BQUI',
      product,
      'CLIENT_VIEW_MODAL',
      'RESULT_STATUS_NEW_ONGOING_HEADER'
    ),
    RESULT_STATUS_NEW_ONGOING_TEXT: translation('BQUI', product, 'CLIENT_VIEW_MODAL', 'RESULT_STATUS_NEW_ONGOING_TEXT'),
  },
  CLIENT_RESULT_STATUS_NOTIFICATION: {
    BACK_BUTTON: translation('BQUI', product, 'CLIENT_RESULT_STATUS_NOTIFICATION', 'BACK_BUTTON'),
    VIEW_PREVIOUS_RESULT_BUTTON: translation(
      'BQUI',
      product,
      'CLIENT_RESULT_STATUS_NOTIFICATION',
      'VIEW_PREVIOUS_RESULT_BUTTON'
    ),
    UNLOCK_RESULT_BUTTON: translation('BQUI', product, 'CLIENT_RESULT_STATUS_NOTIFICATION', 'UNLOCK_RESULT_BUTTON'),
    BUY_CREDITS_BUTTON: translation('BQUI', product, 'CLIENT_RESULT_STATUS_NOTIFICATION', 'BUY_CREDITS_BUTTON'),
    REMINDER_BUTTON: translation('BQUI', product, 'CLIENT_RESULT_STATUS_NOTIFICATION', 'REMINDER_BUTTON'),
    INTERACTION_INVITE_BUTTON: translation(
      'BQUI',
      product,
      'CLIENT_RESULT_STATUS_NOTIFICATION',
      'INTERACTION_INVITE_BUTTON'
    ),
    NO_RESULT_HEADER: translation('BQUI', product, 'CLIENT_RESULT_STATUS_NOTIFICATION', 'NO_RESULT_HEADER'),
    NO_RESULT_TEXT: translation('BQUI', product, 'CLIENT_RESULT_STATUS_NOTIFICATION', 'NO_RESULT_TEXT'),
    INTERACTION_ONGOING_HEADER: translation(
      'BQUI',
      product,
      'CLIENT_RESULT_STATUS_NOTIFICATION',
      'INTERACTION_ONGOING_HEADER'
    ),
    INTERACTION_ONGOING_TEXT: translation(
      'BQUI',
      product,
      'CLIENT_RESULT_STATUS_NOTIFICATION',
      'INTERACTION_ONGOING_TEXT'
    ),
    LOCKED_RESULT_HEADER: translation('BQUI', product, 'CLIENT_RESULT_STATUS_NOTIFICATION', 'LOCKED_RESULT_HEADER'),
    LOCKED_RESULT_TEXT: translation('BQUI', product, 'CLIENT_RESULT_STATUS_NOTIFICATION', 'LOCKED_RESULT_TEXT'),
    NO_CREDITS_HEADER: translation('BQUI', product, 'CLIENT_RESULT_STATUS_NOTIFICATION', 'NO_CREDITS_HEADER'),
    NO_CREDITS_TEXT: translation('BQUI', product, 'CLIENT_RESULT_STATUS_NOTIFICATION', 'NO_CREDITS_TEXT'),
    NO_DASHBOARD_PERMISSION_HEADER: translation(
      'BQUI',
      product,
      'CLIENT_RESULT_STATUS_NOTIFICATION',
      'NO_DASHBOARD_PERMISSION_HEADER'
    ),
    NO_DASHBOARD_PERMISSION_TEXT: translation(
      'BQUI',
      product,
      'CLIENT_RESULT_STATUS_NOTIFICATION',
      'NO_DASHBOARD_PERMISSION_TEXT'
    ),
    CONTACT_SUPPORT_BUTTON: translation('BQUI', product, 'CLIENT_RESULT_STATUS_NOTIFICATION', 'CONTACT_SUPPORT_BUTTON'),
  },
  CLIENT_REPORT: {
    FILENAME: translation('BQUI', product, 'CLIENT_REPORT', 'FILENAME'),
    ANONYMOUS_FILENAME: translation('BQUI', product, 'CLIENT_REPORT', 'ANONYMOUS_FILENAME'),
  },
  EVALUATOR_VIEW: {
    BUTTON_CREATE_INTERACTION_TOOLTIP_STATUS_INTERACTION_IN_PROGRESS: translation(
      'BQUI',
      product,
      'EVALUATOR_VIEW',
      'BUTTON_CREATE_INTERACTION_TOOLTIP_STATUS_INTERACTION_IN_PROGRESS'
    ),
    BUTTON_CREATE_INTERACTION_TOOLTIP_STATUS_INTERACTION_LIMIT_REACHED: translation(
      'BQUI',
      product,
      'EVALUATOR_VIEW',
      'BUTTON_CREATE_INTERACTION_TOOLTIP_STATUS_INTERACTION_LIMIT_REACHED'
    ),
    BUTTON_CREATE_INTERACTION_TOOLTIP_STATUS_ALL_MEASUREMENTS_HAVE_BEEN_COMPLETED: translation(
      'BQUI',
      product,
      'EVALUATOR_VIEW',
      'BUTTON_CREATE_INTERACTION_TOOLTIP_STATUS_ALL_MEASUREMENTS_HAVE_BEEN_COMPLETED'
    ),
    BUTTON_CREATE_INTERACTION_TOOLTIP_STATUS_LATEST_INTERACTION_LOCKED: translation(
      'BQUI',
      product,
      'EVALUATOR_VIEW',
      'BUTTON_CREATE_INTERACTION_TOOLTIP_STATUS_LATEST_INTERACTION_LOCKED'
    ),
    BUTTON_CREATE_USER_TOOLTIP_STATUS_USER_LIMIT_REACHED: translation(
      'BQUI',
      product,
      'EVALUATOR_VIEW',
      'BUTTON_CREATE_USER_TOOLTIP_STATUS_USER_LIMIT_REACHED'
    ),
    BUTTON_CREATE_USER_TOOLTIP_STATUS_TRIAL_MODE: translation(
      'BQUI',
      product,
      'EVALUATOR_VIEW',
      'BUTTON_CREATE_USER_TOOLTIP_STATUS_TRIAL_MODE'
    ),
    CLIENT_TABLE_NO_CLIENTS_MESSAGE: translation('BQUI', product, 'EVALUATOR_VIEW', 'CLIENT_TABLE_NO_CLIENTS_MESSAGE'),
    CLIENT_GROUP_ALL: translation('BQUI', product, 'EVALUATOR_VIEW', 'CLIENT_GROUP_ALL'),
    CLIENT_GROUP_ESTABLISHED: translation('BQUI', product, 'EVALUATOR_VIEW', 'CLIENT_GROUP_ESTABLISHED'),
    CLIENT_GROUP_PROSPECT: translation('BQUI', product, 'EVALUATOR_VIEW', 'CLIENT_GROUP_PROSPECT'),
  },
  ACTIONS: {
    CREATE_CLIENT: translation('BQUI', product, 'ACTIONS', 'CREATE_CLIENT'),
    CREATED_INTERACTION: translation('BQUI', product, 'ACTIONS', 'CREATED_INTERACTION'),
    INVITE_CLIENT_INTERACTION: translation('BQUI', product, 'ACTIONS', 'INVITE_CLIENT_INTERACTION'),
  },
  MESSAGE: {
    START_TRIAL: translation('BQUI', product, 'MESSAGE', 'START_TRIAL'),
    START_TRIAL_BUTTON: translation('BQUI', product, 'MESSAGE', 'START_TRIAL_BUTTON'),
    IX_NOT_DONE: translation('BQUI', product, 'MESSAGE', 'IX_NOT_DONE'),
    IX_NOT_DONE_BUTTON: translation('BQUI', product, 'MESSAGE', 'IX_NOT_DONE_BUTTON'),
    PAYMENT_REQUIRED: translation('BQUI', product, 'MESSAGE', 'PAYMENT_REQUIRED'),
    PAYMENT_REQUIRED_BUTTON: translation('BQUI', product, 'MESSAGE', 'PAYMENT_REQUIRED_BUTTON'),
    CERTIFICATE_AVAILABLE: translation('BQUI', product, 'MESSAGE', 'CERTIFICATE_AVAILABLE'),
    CALCULATING_INTERACTION: translation('BQUI', product, 'MESSAGE', 'CALCULATING_INTERACTION'),
    EMAIL_VERIFICATION_REQUIRED: translation('BQUI', product, 'MESSAGE', 'EMAIL_VERIFICATION_REQUIRED'),
    EMAIL_VERIFICATION_REQUIRED_BUTTON: translation('BQUI', product, 'MESSAGE', 'EMAIL_VERIFICATION_REQUIRED_BUTTON'),
    TRIAL_NA_NO_CREDITS_ADDED: translation('BQUI', product, 'MESSAGE', 'TRIAL_NA_NO_CREDITS_ADDED'),
    TRIAL_NA_NO_CREDITS_ADDED_BUTTON: translation('BQUI', product, 'MESSAGE', 'TRIAL_NA_NO_CREDITS_ADDED_BUTTON'),
    RESULTS_AVAILABLE: translation('BQUI', product, 'MESSAGE', 'RESULTS_AVAILABLE'),
    RESULTS_AVAILABLE_BUTTON: translation('BQUI', product, 'MESSAGE', 'RESULTS_AVAILABLE_BUTTON'),
    RESULTS_AVAILABLE_BUTTON_LINK: translation('BQUI', product, 'MESSAGE', 'RESULTS_AVAILABLE_BUTTON_LINK'),
  },
  AD: {
    TITLE: translation('BQUI', product, 'AD', 'TITLE'),
    TEXT: translation('BQUI', product, 'AD', 'TEXT'),
    LINK: translation('BQUI', product, 'AD', 'LINK'),
  },
  RESULT: {
    REPORT_TITLE: translation('BQUI', product, 'RESULT', 'REPORT_TITLE'),
    REPORT_TEXT: translation('BQUI', product, 'RESULT', 'REPORT_TEXT'),
    REPORT_IMAGE_PATH_ACTIVE: translation('BQUI', product, 'RESULT', 'REPORT_IMAGE_PATH_ACTIVE'),
    REPORT_IMAGE_PATH_INACTIVE: translation('BQUI', product, 'RESULT', 'REPORT_IMAGE_PATH_INACTIVE'),
    REPORT_DOWNLOAD_TOOLTIP_ACTIVE: translation('BQUI', product, 'RESULT', 'REPORT_DOWNLOAD_TOOLTIP_ACTIVE'),
    REPORT_DOWNLOAD_BUTTON_ACTIVE: translation('BQUI', product, 'RESULT', 'REPORT_DOWNLOAD_BUTTON_ACTIVE'),
    REPORT_DOWNLOAD_TOOLTIP_INACTIVE_IX_NOT_DONE: translation(
      'BQUI',
      product,
      'RESULT',
      'REPORT_DOWNLOAD_TOOLTIP_INACTIVE_IX_NOT_DONE'
    ),
    REPORT_DOWNLOAD_BUTTON_EMAIL_VERIFICATION_REQUIRED: translation(
      'BQUI',
      product,
      'RESULT',
      'REPORT_DOWNLOAD_BUTTON_EMAIL_VERIFICATION_REQUIRED'
    ),
    REPORT_DOWNLOAD_TOOLTIP_EMAIL_VERIFICATION_REQUIRED: translation(
      'BQUI',
      product,
      'RESULT',
      'REPORT_DOWNLOAD_TOOLTIP_EMAIL_VERIFICATION_REQUIRED'
    ),
    REPORT_DOWNLOAD_BUTTON_INACTIVE_IX_NOT_DONE: translation(
      'BQUI',
      product,
      'RESULT',
      'REPORT_DOWNLOAD_BUTTON_INACTIVE_IX_NOT_DONE'
    ),
    REPORT_DOWNLOAD_TOOLTIP_INACTIVE_PAYMENT_REQUIRED: translation(
      'BQUI',
      product,
      'RESULT',
      'REPORT_DOWNLOAD_TOOLTIP_INACTIVE_PAYMENT_REQUIRED'
    ),
    REPORT_DOWNLOAD_BUTTON_INACTIVE_PAYMENT_REQUIRED: translation(
      'BQUI',
      product,
      'RESULT',
      'REPORT_DOWNLOAD_BUTTON_INACTIVE_PAYMENT_REQUIRED'
    ),
    CERTIFICATE_TITLE: translation('BQUI', product, 'RESULT', 'CERTIFICATE_TITLE'),
    CERTIFICATE_TEXT: translation('BQUI', product, 'RESULT', 'CERTIFICATE_TEXT'),
    CERTIFICATE_IMAGE_PATH_ACTIVE: translation('BQUI', product, 'RESULT', 'CERTIFICATE_IMAGE_PATH_ACTIVE'),
    CERTIFICATE_IMAGE_PATH_INACTIVE: translation('BQUI', product, 'RESULT', 'CERTIFICATE_IMAGE_PATH_INACTIVE'),
    CERTIFICATE_DOWNLOAD_TOOLTIP_ACTIVE: translation('BQUI', product, 'RESULT', 'CERTIFICATE_DOWNLOAD_TOOLTIP_ACTIVE'),
    CERTIFICATE_DOWNLOAD_BUTTON_ACTIVE: translation('BQUI', product, 'RESULT', 'CERTIFICATE_DOWNLOAD_BUTTON_ACTIVE'),
    CERTIFICATE_DOWNLOAD_TOOLTIP_INACTIVE_IX_NOT_DONE: translation(
      'BQUI',
      product,
      'RESULT',
      'CERTIFICATE_DOWNLOAD_TOOLTIP_INACTIVE_IX_NOT_DONE'
    ),
    CERTIFICATE_DOWNLOAD_BUTTON_INACTIVE_IX_NOT_DONE: translation(
      'BQUI',
      product,
      'RESULT',
      'CERTIFICATE_DOWNLOAD_BUTTON_INACTIVE_IX_NOT_DONE'
    ),
    CERTIFICATE_DOWNLOAD_TOOLTIP_EMAIL_VERIFICATION_REQUIRED: translation(
      'BQUI',
      product,
      'RESULT',
      'CERTIFICATE_DOWNLOAD_TOOLTIP_EMAIL_VERIFICATION_REQUIRED'
    ),
    CERTIFICATE_DOWNLOAD_BUTTON_EMAIL_VERIFICATION_REQUIRED: translation(
      'BQUI',
      product,
      'RESULT',
      'CERTIFICATE_DOWNLOAD_BUTTON_EMAIL_VERIFICATION_REQUIRED'
    ),
    CERTIFICATE_DOWNLOAD_TOOLTIP_INACTIVE_PAYMENT_REQUIRED: translation(
      'BQUI',
      product,
      'RESULT',
      'CERTIFICATE_DOWNLOAD_TOOLTIP_INACTIVE_PAYMENT_REQUIRED'
    ),
    CERTIFICATE_DOWNLOAD_BUTTON_INACTIVE_PAYMENT_REQUIRED: translation(
      'BQUI',
      product,
      'RESULT',
      'CERTIFICATE_DOWNLOAD_BUTTON_INACTIVE_PAYMENT_REQUIRED'
    ),
    CERTIFICATE_SHARE_TOOLTIP_ACTIVE: translation('BQUI', product, 'RESULT', 'CERTIFICATE_SHARE_TOOLTIP_ACTIVE'),
    CERTIFICATE_SHARE_BUTTON_ACTIVE: translation('BQUI', product, 'RESULT', 'CERTIFICATE_SHARE_BUTTON_ACTIVE'),
    CERTIFICATE_SHARE_TOOLTIP_INACTIVE_IX_NOT_DONE: translation(
      'BQUI',
      product,
      'RESULT',
      'CERTIFICATE_SHARE_TOOLTIP_INACTIVE_IX_NOT_DONE'
    ),
    CERTIFICATE_SHARE_BUTTON_INACTIVE_IX_NOT_DONE: translation(
      'BQUI',
      product,
      'RESULT',
      'CERTIFICATE_SHARE_BUTTON_INACTIVE_IX_NOT_DONE'
    ),
    CERTIFICATE_SHARE_TOOLTIP_EMAIL_VERIFICATION_REQUIRED: translation(
      'BQUI',
      product,
      'RESULT',
      'CERTIFICATE_SHARE_TOOLTIP_EMAIL_VERIFICATION_REQUIRED'
    ),
    CERTIFICATE_SHARE_BUTTON_EMAIL_VERIFICATION_REQUIRED: translation(
      'BQUI',
      product,
      'RESULT',
      'CERTIFICATE_SHARE_BUTTON_EMAIL_VERIFICATION_REQUIRED'
    ),
    CERTIFICATE_SHARE_TOOLTIP_INACTIVE_PAYMENT_REQUIRED: translation(
      'BQUI',
      product,
      'RESULT',
      'CERTIFICATE_SHARE_TOOLTIP_INACTIVE_PAYMENT_REQUIRED'
    ),
    CERTIFICATE_SHARE_BUTTON_INACTIVE_PAYMENT_REQUIRED: translation(
      'BQUI',
      product,
      'RESULT',
      'CERTIFICATE_SHARE_BUTTON_INACTIVE_PAYMENT_REQUIRED'
    ),
    CERTIFICATE_VIEW_VERIFICATION_TOOLTIP_ACTIVE: translation(
      'BQUI',
      product,
      'RESULT',
      'CERTIFICATE_VIEW_VERIFICATION_TOOLTIP_ACTIVE'
    ),
    CERTIFICATE_VIEW_VERIFICATION_BUTTON_ACTIVE: translation(
      'BQUI',
      product,
      'RESULT',
      'CERTIFICATE_VIEW_VERIFICATION_BUTTON_ACTIVE'
    ),
    CERTIFICATE_VIEW_VERIFICATION_TOOLTIP_INACTIVE_IX_NOT_DONE: translation(
      'BQUI',
      product,
      'RESULT',
      'CERTIFICATE_VIEW_VERIFICATION_TOOLTIP_INACTIVE_IX_NOT_DONE'
    ),
    CERTIFICATE_VIEW_VERIFICATION_BUTTON_INACTIVE_IX_NOT_DONE: translation(
      'BQUI',
      product,
      'RESULT',
      'CERTIFICATE_VIEW_VERIFICATION_BUTTON_INACTIVE_IX_NOT_DONE'
    ),
    CERTIFICATE_VIEW_VERIFICATION_TOOLTIP_EMAIL_VERIFICATION_REQUIRED: translation(
      'BQUI',
      product,
      'RESULT',
      'CERTIFICATE_VIEW_VERIFICATION_TOOLTIP_EMAIL_VERIFICATION_REQUIRED'
    ),
    CERTIFICATE_VIEW_VERIFICATION_BUTTON_EMAIL_VERIFICATION_REQUIRED: translation(
      'BQUI',
      product,
      'RESULT',
      'CERTIFICATE_VIEW_VERIFICATION_BUTTON_EMAIL_VERIFICATION_REQUIRED'
    ),
    CERTIFICATE_VIEW_VERIFICATION_TOOLTIP_INACTIVE_PAYMENT_REQUIRED: translation(
      'BQUI',
      product,
      'RESULT',
      'CERTIFICATE_VIEW_VERIFICATION_TOOLTIP_INACTIVE_PAYMENT_REQUIRED'
    ),
    CERTIFICATE_VIEW_VERIFICATION_BUTTON_INACTIVE_PAYMENT_REQUIRED: translation(
      'BQUI',
      product,
      'RESULT',
      'CERTIFICATE_VIEW_VERIFICATION_BUTTON_INACTIVE_PAYMENT_REQUIRED'
    ),
    BADGE_TITLE: translation('BQUI', product, 'RESULT', 'BADGE_TITLE'),
    BADGE_TEXT: translation('BQUI', product, 'RESULT', 'BADGE_TEXT'),
    BADGE_IMAGE_PATH_ACTIVE: translation('BQUI', product, 'RESULT', 'BADGE_IMAGE_PATH_ACTIVE'),
    BADGE_IMAGE_PATH_INACTIVE: translation('BQUI', product, 'RESULT', 'BADGE_IMAGE_PATH_INACTIVE'),
    BADGE_DOWNLOAD_PATH: translation('BQUI', product, 'RESULT', 'BADGE_DOWNLOAD_PATH'),
    BADGE_DOWNLOAD_TOOLTIP_ACTIVE: translation('BQUI', product, 'RESULT', 'BADGE_DOWNLOAD_TOOLTIP_ACTIVE'),
    BADGE_DOWNLOAD_BUTTON_ACTIVE: translation('BQUI', product, 'RESULT', 'BADGE_DOWNLOAD_BUTTON_ACTIVE'),
    BADGE_DOWNLOAD_TOOLTIP_INACTIVE_IX_NOT_DONE: translation(
      'BQUI',
      product,
      'RESULT',
      'BADGE_DOWNLOAD_TOOLTIP_INACTIVE_IX_NOT_DONE'
    ),
    BADGE_DOWNLOAD_BUTTON_INACTIVE_IX_NOT_DONE: translation(
      'BQUI',
      product,
      'RESULT',
      'BADGE_DOWNLOAD_BUTTON_INACTIVE_IX_NOT_DONE'
    ),
    BADGE_DOWNLOAD_TOOLTIP_EMAIL_VERIFICATION_REQUIRED: translation(
      'BQUI',
      product,
      'RESULT',
      'BADGE_DOWNLOAD_TOOLTIP_EMAIL_VERIFICATION_REQUIRED'
    ),
    BADGE_DOWNLOAD_BUTTON_EMAIL_VERIFICATION_REQUIRED: translation(
      'BQUI',
      product,
      'RESULT',
      'BADGE_DOWNLOAD_BUTTON_EMAIL_VERIFICATION_REQUIRED'
    ),
    BADGE_DOWNLOAD_TOOLTIP_INACTIVE_PAYMENT_REQUIRED: translation(
      'BQUI',
      product,
      'RESULT',
      'BADGE_DOWNLOAD_TOOLTIP_INACTIVE_PAYMENT_REQUIRED'
    ),
    BADGE_DOWNLOAD_BUTTON_INACTIVE_PAYMENT_REQUIRED: translation(
      'BQUI',
      product,
      'RESULT',
      'BADGE_DOWNLOAD_BUTTON_INACTIVE_PAYMENT_REQUIRED'
    ),
    BADGE_SHARE_TOOLTIP: translation('BQUI', product, 'RESULT', 'BADGE_SHARE_TOOLTIP'),
    BADGE_SHARE_BUTTON: translation('BQUI', product, 'RESULT', 'BADGE_SHARE_BUTTON'),
    REPORT_FILENAME: translation('BQUI', product, 'RESULT', 'REPORT_FILENAME'),
    CERTIFICATE_FILENAME: translation('BQUI', product, 'RESULT', 'CERTIFICATE_FILENAME'),
    BADGE_FILENAME: translation('BQUI', product, 'RESULT', 'BADGE_FILENAME'),
    DUMMYREPORT_DOWNLOAD_BUTTON_TEXT: translation('BQUI', product, 'RESULT', 'DUMMYREPORT_DOWNLOAD_BUTTON_TEXT'),
    DUMMYREPORT_DOWNLOAD_BUTTON_LINK: translation('BQUI', product, 'RESULT', 'DUMMYREPORT_DOWNLOAD_BUTTON_LINK'),
    REPORT_DOWNLOAD_BUTTON_TEXT: translation('BQUI', product, 'RESULT', 'REPORT_DOWNLOAD_BUTTON_TEXT'),
    MATRIX_SUCC_TITLE: translation('BQUI', product, 'RESULT', 'MATRIX_SUCC_TITLE'),
    MATRIX_SUCC_TOP: translation('BQUI', product, 'RESULT', 'MATRIX_SUCC_TOP'),
    MATRIX_SUCC_BOTTOM: translation('BQUI', product, 'RESULT', 'MATRIX_SUCC_BOTTOM'),
    MATRIX_RISK_TITLE: translation('BQUI', product, 'RESULT', 'MATRIX_RISK_TITLE'),
    MATRIX_RISK_LEFT: translation('BQUI', product, 'RESULT', 'MATRIX_RISK_LEFT'),
    MATRIX_RISK_RIGHT: translation('BQUI', product, 'RESULT', 'MATRIX_RISK_RIGHT'),
    MATRIX_LEGEND: translation('BQUI', product, 'RESULT', 'MATRIX_LEGEND'),
    MATRIX_NO_RESULT_LEGEND: translation('BQUI', product, 'RESULT', 'MATRIX_NO_RESULT_LEGEND'),
    MATRIX_TITLE: translation('BQUI', product, 'RESULT', 'MATRIX_TITLE'),
    MATRIX_MAIN_TEXT: translation('BQUI', product, 'RESULT', 'MATRIX_MAIN_TEXT'),
    MATRIX_IBUTTON_LINK: translation('BQUI', product, 'RESULT', 'MATRIX_IBUTTON_LINK'),
    MATRIX_SUB_TEXT: translation('BQUI', product, 'RESULT', 'MATRIX_SUB_TEXT'),
    MATRIX_MODAL_IMG_SRC: translation('BQUI', product, 'RESULT', 'MATRIX_MODAL_IMG_SRC'),
    MATRIX_MODAL_SUB_TEXT: translation('BQUI', product, 'RESULT', 'MATRIX_MODAL_SUB_TEXT'),
    MATRIX_MODAL_MAIN_TEXT: translation('BQUI', product, 'RESULT', 'MATRIX_MODAL_MAIN_TEXT'),
    BQSCORE_TITLE: translation('BQUI', product, 'RESULT', 'BQSCORE_TITLE'),
    BQSCORE_SUB_TITLE: translation('BQUI', product, 'RESULT', 'BQSCORE_SUB_TITLE'),
    BQSCORE_TEXT: translation('BQUI', product, 'RESULT', 'BQSCORE_TEXT'),
    BQSCORE_NO_RESULT_LABEL: translation('BQUI', product, 'RESULT', 'BQSCORE_NO_RESULT_LABEL'),
  },
  SIGN_UP_COMPONENT: {
    EMAIL_VERIFICATION_SUCCESS_HEADER: translation(
      'BQUI',
      product,
      'SIGN_UP_COMPONENT',
      'EMAIL_VERIFICATION_SUCCESS_HEADER'
    ),
    EMAIL_VERIFICATION_SUCCESS_TEXT: translation(
      'BQUI',
      product,
      'SIGN_UP_COMPONENT',
      'EMAIL_VERIFICATION_SUCCESS_TEXT'
    ),
    START_INTERACTION: translation('BQUI', product, 'SIGN_UP_COMPONENT', 'START_INTERACTION'),
    SKIP_INTERACTION: translation('BQUI', product, 'SIGN_UP_COMPONENT', 'SKIP_INTERACTION'),
    SIGN_UP_BUTTON: translation('BQUI', product, 'SIGN_UP_COMPONENT', 'SIGN_UP_BUTTON'),
    CONTINUE: translation('BQUI', product, 'SIGN_UP_COMPONENT', 'CONTINUE'),
    REDIRECT_TO_PRODUCT: translation('BQUI', product, 'SIGN_UP_COMPONENT', 'REDIRECT_TO_PRODUCT'),
    REGISTRATION_SUCCESS_TEXT: translation('BQUI', product, 'SIGN_UP_COMPONENT', 'REGISTRATION_SUCCESS_TEXT'),
  },
  CHECKOUT_SUCCESS_COMPONENT: {
    HEADER: translation('BQUI', product, 'CHECKOUT_SUCCESS_COMPONENT', 'HEADER'),
    DESCRIPTION: translation('BQUI', product, 'CHECKOUT_SUCCESS_COMPONENT', 'DESCRIPTION'),
  },
  CROSS_SIGN_UP_COMPONENT: {
    SUCCESS_HEADER: translation('BQUI', product, 'CROSS_SIGN_UP_COMPONENT', 'SUCCESS_HEADER'),
    SUCCESS_TEXT: translation('BQUI', product, 'CROSS_SIGN_UP_COMPONENT', 'SUCCESS_TEXT'),
    START_INTERACTION: translation('BQUI', product, 'CROSS_SIGN_UP_COMPONENT', 'START_INTERACTION'),
    SKIP_INTERACTION: translation('BQUI', product, 'CROSS_SIGN_UP_COMPONENT', 'SKIP_INTERACTION'),
  },
  APP_AD: {
    TITLE: translation('BQUI', product, 'APP_AD', 'TITLE'),
    TEXT: translation('BQUI', product, 'APP_AD', 'TEXT'),
    IMAGE_PATH: translation('BQUI', product, 'APP_AD', 'IMAGE_PATH'),
    START_BUTTON: translation('BQUI', product, 'APP_AD', 'START_BUTTON'),
    START_BUTTON_EXTERNAL_LINK: translation('BQUI', product, 'APP_AD', 'START_BUTTON_EXTERNAL_LINK'),
    FAQ_TITLE: translation('BQUI', product, 'APP_AD', 'FAQ_TITLE'),
    HAVE_QUESTIONS_TITLE: translation('BQUI', product, 'APP_AD', 'HAVE_QUESTIONS_TITLE'),
    HAVE_QUESTIONS_TEXT: translation('BQUI', product, 'APP_AD', 'HAVE_QUESTIONS_TEXT'),
    HAVE_QUESTIONS_BUTTON_TEXT: translation('BQUI', product, 'APP_AD', 'HAVE_QUESTIONS_BUTTON_TEXT'),
    HAVE_QUESTIONS_BUTTON_MAILTO: translation('BQUI', product, 'APP_AD', 'HAVE_QUESTIONS_BUTTON_MAILTO'),
    CROSS_SIGN_UP_SUCCESS: translation('BQUI', product, 'APP_AD', 'CROSS_SIGN_UP_SUCCESS'),
    ERROR_PRODUCT_ALREADY_ADDED: translation('BQUI', product, 'APP_AD', 'ERROR_PRODUCT_ALREADY_ADDED'),
    BUY_BUTTON: translation('BQUI', product, 'APP_AD', 'BUY_BUTTON'),
    GET_SAMPLE_BUTTON: translation('BQUI', product, 'APP_AD', 'GET_SAMPLE_BUTTON'),
  },
  VERIFY_EMAIL_MODAL: {
    TITLE: translation('BQUI', product, 'VERIFY_EMAIL_MODAL', 'TITLE'),
    MESSAGE: translation('BQUI', product, 'VERIFY_EMAIL_MODAL', 'MESSAGE'),
    BUTTON_RESEND_CONFIRMATION: translation('BQUI', product, 'VERIFY_EMAIL_MODAL', 'BUTTON_RESEND_CONFIRMATION'),
    BUTTON_CONFIRM_LATER: translation('BQUI', product, 'VERIFY_EMAIL_MODAL', 'BUTTON_CONFIRM_LATER'),
    HELP_TEXT: translation('BQUI', product, 'VERIFY_EMAIL_MODAL', 'HELP_TEXT'),
  },
  RESEND_CONFIRMATION_EMAIL_NOTIFICATION: {
    SUCCESS: translation('BQUI', product, 'RESEND_CONFIRMATION_EMAIL_NOTIFICATION', 'SUCCESS'),
    ERROR: translation('BQUI', product, 'RESEND_CONFIRMATION_EMAIL_NOTIFICATION', 'ERROR'),
  },
  CONTINUE_INTERACTION_MODAL: {
    TITLE: translation('BQUI', product, 'CONTINUE_INTERACTION_MODAL', 'TITLE'),
    TEXT: translation('BQUI', product, 'CONTINUE_INTERACTION_MODAL', 'TEXT'),
    BUTTON_START: translation('BQUI', product, 'CONTINUE_INTERACTION_MODAL', 'BUTTON_START'),
    BUTTON_CANCEL: translation('BQUI', product, 'CONTINUE_INTERACTION_MODAL', 'BUTTON_CANCEL'),
  },
  RESEND_CONFIRMATION_MODAL: {
    TITLE: translation('BQUI', product, 'RESEND_CONFIRMATION_MODAL', 'TITLE'),
    TEXT: translation('BQUI', product, 'RESEND_CONFIRMATION_MODAL', 'TEXT'),
    BUTTON_CONFIRM: translation('BQUI', product, 'RESEND_CONFIRMATION_MODAL', 'BUTTON_CONFIRM'),
    BUTTON_CANCEL: translation('BQUI', product, 'RESEND_CONFIRMATION_MODAL', 'BUTTON_CANCEL'),
  },
  START_TRIAL_MODAL: {
    TITLE: translation('BQUI', product, 'START_TRIAL_MODAL', 'TITLE'),
    TEXT: translation('BQUI', product, 'START_TRIAL_MODAL', 'TEXT'),
    COLLAPSE_TEXT: translation('BQUI', product, 'START_TRIAL_MODAL', 'COLLAPSE_TEXT'),
    BUTTON_START: translation('BQUI', product, 'START_TRIAL_MODAL', 'BUTTON_START'),
    BUTTON_CANCEL: translation('BQUI', product, 'START_TRIAL_MODAL', 'BUTTON_CANCEL'),
    DISMISS_TEXT: translation('BQUI', product, 'START_TRIAL_MODAL', 'DISMISS_TEXT'),
    DISMISS_BUTTON: translation('BQUI', product, 'START_TRIAL_MODAL', 'DISMISS_BUTTON'),
    DISMISS_BUTTON_TOOLTIP: translation('BQUI', product, 'START_TRIAL_MODAL', 'DISMISS_BUTTON_TOOLTIP'),
  },
  GUIDE: {
    TITLE: translation('BQUI', product, 'GUIDE', 'TITLE'),
    BUTTON: translation('BQUI', product, 'GUIDE', 'BUTTON'),
    DEFAULT_BI: translation('BQUI', product, 'GUIDE', 'DEFAULT_BI'),
    DEFAULT_DASHBOARD: translation('BQUI', product, 'GUIDE', 'DEFAULT_DASHBOARD'),
    STARTER_ENTERPRISE: translation('BQUI', product, 'GUIDE', 'STARTER_ENTERPRISE'),
    STARTER_SELF_SERVICE: translation('BQUI', product, 'GUIDE', 'STARTER_SELF_SERVICE'),
  },
});

/**
 * common, not product dependent BQUI translations
 */
const BQUI_BASE_COMMON = {
  REQUIREMENT_PROFILES_MANAGEMENT_COMPONENT: {
    TITLE: translation('BQUI', undefined, 'REQUIREMENT_PROFILES_MANAGEMENT_COMPONENT', 'TITLE'),
    ACTIVE_PROFILE_TITLE: translation(
      'BQUI',
      undefined,
      'REQUIREMENT_PROFILES_MANAGEMENT_COMPONENT',
      'ACTIVE_PROFILE_TITLE'
    ),
    SELECT_PROFILE: translation('BQUI', undefined, 'REQUIREMENT_PROFILES_MANAGEMENT_COMPONENT', 'SELECT_PROFILE'),
    DEACTIVATE_PROFILE: translation(
      'BQUI',
      undefined,
      'REQUIREMENT_PROFILES_MANAGEMENT_COMPONENT',
      'DEACTIVATE_PROFILE'
    ),
    CREATE_PROFILE: translation('BQUI', undefined, 'REQUIREMENT_PROFILES_MANAGEMENT_COMPONENT', 'CREATE_PROFILE'),
    UPDATE_PROFILE: translation('BQUI', undefined, 'REQUIREMENT_PROFILES_MANAGEMENT_COMPONENT', 'UPDATE_PROFILE'),
    SAVE_PROFILE: translation('BQUI', undefined, 'REQUIREMENT_PROFILES_MANAGEMENT_COMPONENT', 'SAVE_PROFILE'),
    PROFILE_FORM_NAME: translation('BQUI', undefined, 'REQUIREMENT_PROFILES_MANAGEMENT_COMPONENT', 'PROFILE_FORM_NAME'),
    PROFILE_FORM_NAME_PLACEHOLDER: translation(
      'BQUI',
      undefined,
      'REQUIREMENT_PROFILES_MANAGEMENT_COMPONENT',
      'PROFILE_FORM_NAME_PLACEHOLDER'
    ),
    SCALE_LABEL_1: translation('BQUI', undefined, 'REQUIREMENT_PROFILES_MANAGEMENT_COMPONENT', 'SCALE_LABEL_1'),
    SCALE_LABEL_2: translation('BQUI', undefined, 'REQUIREMENT_PROFILES_MANAGEMENT_COMPONENT', 'SCALE_LABEL_2'),
    SCALE_LABEL_4: translation('BQUI', undefined, 'REQUIREMENT_PROFILES_MANAGEMENT_COMPONENT', 'SCALE_LABEL_4'),
    SCALE_LABEL_8: translation('BQUI', undefined, 'REQUIREMENT_PROFILES_MANAGEMENT_COMPONENT', 'SCALE_LABEL_8'),
    SCALE_LABEL_16: translation('BQUI', undefined, 'REQUIREMENT_PROFILES_MANAGEMENT_COMPONENT', 'SCALE_LABEL_16'),
    BACK_TO_PROFILES_BUTTON: translation(
      'BQUI',
      undefined,
      'REQUIREMENT_PROFILES_MANAGEMENT_COMPONENT',
      'BACK_TO_PROFILES_BUTTON'
    ),
  },
  REQUIREMENT_PROFILES_TABLE_COMPONENT: {
    ACTIVATE_PROFILE: translation('BQUI', undefined, 'REQUIREMENT_PROFILES_TABLE_COMPONENT', 'ACTIVATE_PROFILE'),
    TABLE_NO_DATA_MESSAGE: translation(
      'BQUI',
      undefined,
      'REQUIREMENT_PROFILES_TABLE_COMPONENT',
      'TABLE_NO_DATA_MESSAGE'
    ),
  },
  COMMON: {
    BUTTON_CANCEL: translation('BQUI', undefined, 'COMMON', 'BUTTON_CANCEL'),
    BUTTON_CONFIRM: translation('BQUI', undefined, 'COMMON', 'BUTTON_CONFIRM'),
    BUTTON_CREATE: translation('BQUI', undefined, 'COMMON', 'BUTTON_CREATE'),
    BUTTON_REVOKE: translation('BQUI', undefined, 'COMMON', 'BUTTON_REVOKE'),
    BUTTON_DOWNLOAD: translation('BQUI', undefined, 'COMMON', 'BUTTON_DOWNLOAD'),
    BUTTON_BACK_TO_OVERVIEW: translation('BQUI', undefined, 'COMMON', 'BUTTON_BACK_TO_OVERVIEW'),
    BUTTON_BACK_TO_PROFILE: translation('BQUI', undefined, 'COMMON', 'BUTTON_BACK_TO_PROFILE'),
    LANGUAGE_ENGLISH: translation('BQUI', undefined, 'COMMON', 'LANGUAGE_ENGLISH'),
    LANGUAGE_GERMAN: translation('BQUI', undefined, 'COMMON', 'LANGUAGE_GERMAN'),
    START_DATE: translation('BQUI', undefined, 'COMMON', 'START_DATE'),
    END_DATE: translation('BQUI', undefined, 'COMMON', 'END_DATE'),
    TOAST_TITLE_SUCCESS: translation('BQUI', undefined, 'COMMON', 'TOAST_TITLE_SUCCESS'),
    TOAST_TITLE_ERROR: translation('BQUI', undefined, 'COMMON', 'TOAST_TITLE_ERROR'),
    TABLE_NO_DATA_MESSAGE: translation('BQUI', undefined, 'COMMON', 'TABLE_NO_DATA_MESSAGE'),
    INPUT_NAME: translation('BQUI', undefined, 'COMMON', 'INPUT_NAME'),
    INPUT_FIRST_NAME: translation('BQUI', undefined, 'COMMON', 'INPUT_FIRST_NAME'),
    INPUT_LAST_NAME: translation('BQUI', undefined, 'COMMON', 'INPUT_LAST_NAME'),
    INPUT_EMAIL: translation('BQUI', undefined, 'COMMON', 'INPUT_EMAIL'),
    INPUT_EMAIL_VALIDATION_ERROR: translation('BQUI', undefined, 'COMMON', 'INPUT_EMAIL_VALIDATION_ERROR'),
    INPUT_ADDRESS: translation('BQUI', undefined, 'COMMON', 'INPUT_ADDRESS'),
    INPUT_CLIENT_GROUP: translation('BQUI', undefined, 'COMMON', 'INPUT_CLIENT_GROUP'),
    INPUT_USER_SEARCH_PLACEHOLDER: translation('BQUI', undefined, 'COMMON', 'INPUT_USER_SEARCH_PLACEHOLDER'),
    REQUIRED_INPUT_VALIDATION_ERROR: translation('BQUI', undefined, 'COMMON', 'REQUIRED_INPUT_VALIDATION_ERROR'),
    REQUIRED_INPUT_TOS_NOT_ACCEPTED_ERROR: translation(
      'BQUI',
      undefined,
      'COMMON',
      'REQUIRED_INPUT_TOS_NOT_ACCEPTED_ERROR'
    ),
    REQUIRED_INPUT_MARKETING_TOS_NOT_ACCEPTED_ERROR: translation(
      'BQUI',
      undefined,
      'COMMON',
      'REQUIRED_INPUT_MARKETING_TOS_NOT_ACCEPTED_ERROR'
    ),
    MINIMUM_LENGTH_VALIDATION_ERROR: translation('BQUI', undefined, 'COMMON', 'MINIMUM_LENGTH_VALIDATION_ERROR'),
    TABLE_HEADER_CREATED: translation('BQUI', undefined, 'COMMON', 'TABLE_HEADER_CREATED'),
    TABLE_HEADER_COMPLETED: translation('BQUI', undefined, 'COMMON', 'TABLE_HEADER_COMPLETED'),
    TABLE_HEADER_VALID_UNTIL: translation('BQUI', undefined, 'COMMON', 'TABLE_HEADER_VALID_UNTIL'),
    TABLE_HEADER_NAME: translation('BQUI', undefined, 'COMMON', 'TABLE_HEADER_NAME'),
    TABLE_HEADER_STATUS: translation('BQUI', undefined, 'COMMON', 'TABLE_HEADER_STATUS'),
    NO_RESULTS_AVAILABLE: translation('BQUI', undefined, 'COMMON', 'NO_RESULTS_AVAILABLE'),
    NOTIFICATION_DOWNLOAD_REPORT_SUCCESS: translation(
      'BQUI',
      undefined,
      'NOTIFICATIONS',
      'NOTIFICATION_DOWNLOAD_REPORT_SUCCESS'
    ),
    NOTIFICATION_DOWNLOAD_REPORT_ERROR: translation(
      'BQUI',
      undefined,
      'NOTIFICATIONS',
      'NOTIFICATION_DOWNLOAD_REPORT_ERROR'
    ),
    NOTIFICATION_SAVE_SUCCESS: translation('BQUI', undefined, 'NOTIFICATIONS', 'NOTIFICATION_SAVE_SUCCESS'),
    NOTIFICATION_SAVE_ERROR: translation('BQUI', undefined, 'NOTIFICATIONS', 'NOTIFICATION_SAVE_ERROR'),
  },
  LOGIN_COMPONENT: {
    LOGIN_BUTTON: translation('BQUI', undefined, 'LOGIN_COMPONENT', 'LOGIN_BUTTON'),
    EXTERNAL_LOGIN_BUTTON: translation('BQUI', undefined, 'LOGIN_COMPONENT', 'EXTERNAL_LOGIN_BUTTON'),
    LOGIN_ERROR: translation('BQUI', undefined, 'LOGIN_COMPONENT', 'LOGIN_ERROR'),
    PASSWORD_RESET_BUTTON: translation('BQUI', undefined, 'LOGIN_COMPONENT', 'PASSWORD_RESET_BUTTON'),
    EMAIL_PLACEHOLDER: translation('BQUI', undefined, 'LOGIN_COMPONENT', 'EMAIL_PLACEHOLDER'),
    PASSWORD_PLACEHOLDER: translation('BQUI', undefined, 'LOGIN_COMPONENT', 'PASSWORD_PLACEHOLDER'),
    SIGN_UP_BUTTON: translation('BQUI', undefined, 'LOGIN_COMPONENT', 'SIGN_UP_BUTTON'),
    BQA_REDIRECT_BUTTON: translation('BQUI', undefined, 'LOGIN_COMPONENT', 'BQA_REDIRECT_BUTTON'),
    BQA_REDIRECT_BUTTON_LINK: translation('BQUI', undefined, 'LOGIN_COMPONENT', 'BQA_REDIRECT_BUTTON_LINK'),
    BQ_CERTIFICATE_REDIRECT_BUTTON: translation('BQUI', undefined, 'LOGIN_COMPONENT', 'BQ_CERTIFICATE_REDIRECT_BUTTON'),
    BQ_CERTIFICATE_REDIRECT_BUTTON_LINK: translation(
      'BQUI',
      undefined,
      'LOGIN_COMPONENT',
      'BQ_CERTIFICATE_REDIRECT_BUTTON_LINK'
    ),
    REDIRECT_TITLE: translation('BQUI', undefined, 'LOGIN_COMPONENT', 'REDIRECT_TITLE'),
    REDIRECT_TEXT: translation('BQUI', undefined, 'LOGIN_COMPONENT', 'REDIRECT_TEXT'),
  },
  SIGN_UP_COMPONENT: {
    FIRST_NAME_PLACEHOLDER: translation('BQUI', undefined, 'SIGN_UP_COMPONENT', 'FIRST_NAME_PLACEHOLDER'),
    LAST_NAME_PLACEHOLDER: translation('BQUI', undefined, 'SIGN_UP_COMPONENT', 'LAST_NAME_PLACEHOLDER'),
    EMAIL_PLACEHOLDER: translation('BQUI', undefined, 'SIGN_UP_COMPONENT', 'EMAIL_PLACEHOLDER'),
    ORGANIZATION_NAME_PLACEHOLDER: translation('BQUI', undefined, 'SIGN_UP_COMPONENT', 'ORGANIZATION_NAME_PLACEHOLDER'),
    PASSWORD_PLACEHOLDER: translation('BQUI', undefined, 'SIGN_UP_COMPONENT', 'PASSWORD_PLACEHOLDER'),
    PASSWORD_CONFIRMATION_PLACEHOLDER: translation(
      'BQUI',
      undefined,
      'SIGN_UP_COMPONENT',
      'PASSWORD_CONFIRMATION_PLACEHOLDER'
    ),
    LANGUAGE_PLACEHOLDER: translation('BQUI', undefined, 'SIGN_UP_COMPONENT', 'LANGUAGE_PLACEHOLDER'),
    TOS_CHECKBOX_LABEL: translation('BQUI', undefined, 'SIGN_UP_COMPONENT', 'TOS_CHECKBOX_LABEL'),
    SIGN_UP_BUTTON: translation('BQUI', undefined, 'SIGN_UP_COMPONENT', 'SIGN_UP_BUTTON'),
    EXTERNAL_SIGN_UP_BUTTON: translation('BQUI', undefined, 'SIGN_UP_COMPONENT', 'EXTERNAL_SIGN_UP_BUTTON'),
    BUTTON_ERROR_BACK: translation('BQUI', undefined, 'SIGN_UP_COMPONENT', 'BUTTON_ERROR_BACK'),
    REGISTRATION_FORM_HEADER: translation('BQUI', undefined, 'SIGN_UP_COMPONENT', 'REGISTRATION_FORM_HEADER'),
    REGISTRATION_SUCCESS_HEADER: translation('BQUI', undefined, 'SIGN_UP_COMPONENT', 'REGISTRATION_SUCCESS_HEADER'),
    REGISTRATION_SUCCESS_TEXT: translation('BQUI', undefined, 'SIGN_UP_COMPONENT', 'REGISTRATION_SUCCESS_TEXT'),
    CREATING_ACCOUNT_WITH_SOCIALS_IN_PROGRESS_TEXT: translation(
      'BQUI',
      undefined,
      'SIGN_UP_COMPONENT',
      'CREATING_ACCOUNT_WITH_SOCIALS_IN_PROGRESS_TEXT'
    ),
    CREATING_ACCOUNT_IN_PROGRESS_TEXT: translation(
      'BQUI',
      undefined,
      'SIGN_UP_COMPONENT',
      'CREATING_ACCOUNT_IN_PROGRESS_TEXT'
    ),
    SUBMITTING_EMAIL_IN_PROGRESS_TEXT: translation(
      'BQUI',
      undefined,
      'SIGN_UP_COMPONENT',
      'SUBMITTING_EMAIL_IN_PROGRESS_TEXT'
    ),
    ERROR_PRODUCT_ALREADY_REGISTERED: translation(
      'BQUI',
      undefined,
      'SIGN_UP_COMPONENT',
      'ERROR_PRODUCT_ALREADY_REGISTERED'
    ),
    ERROR_EMAIL_ALREADY_EXISTS: translation('BQUI', undefined, 'SIGN_UP_COMPONENT', 'ERROR_EMAIL_ALREADY_EXISTS'),
    ERROR_NOT_ALLOWED_FOR_ACCOUNT_TYPE: translation(
      'BQUI',
      undefined,
      'SIGN_UP_COMPONENT',
      'ERROR_NOT_ALLOWED_FOR_ACCOUNT_TYPE'
    ),
    ALREADY_VERIFIED_HEADER: translation('BQUI', undefined, 'SIGN_UP_COMPONENT', 'ALREADY_VERIFIED_HEADER'),
    ALREADY_VERIFIED_TEXT: translation('BQUI', undefined, 'SIGN_UP_COMPONENT', 'ALREADY_VERIFIED_TEXT'),
    EMAIL_VERIFICATION_IN_PROGRESS_TEXT: translation(
      'BQUI',
      undefined,
      'SIGN_UP_COMPONENT',
      'EMAIL_VERIFICATION_IN_PROGRESS_TEXT'
    ),
    EMAIL_VERIFICATION_SUCCESS_HEADER: translation(
      'BQUI',
      undefined,
      'SIGN_UP_COMPONENT',
      'EMAIL_VERIFICATION_SUCCESS_HEADER'
    ),
    EMAIL_VERIFICATION_SUCCESS_TEXT: translation(
      'BQUI',
      undefined,
      'SIGN_UP_COMPONENT',
      'EMAIL_VERIFICATION_SUCCESS_TEXT'
    ),
    START_INTERACTION: translation('BQUI', undefined, 'SIGN_UP_COMPONENT', 'START_INTERACTION'),
    SKIP_INTERACTION: translation('BQUI', undefined, 'SIGN_UP_COMPONENT', 'SKIP_INTERACTION'),
    BUY_PACKAGE: translation('BQUI', undefined, 'SIGN_UP_COMPONENT', 'BUY_PACKAGE'),
    CONTINUE: translation('BQUI', undefined, 'SIGN_UP_COMPONENT', 'CONTINUE'),
    BACK: translation('BQUI', undefined, 'SIGN_UP_COMPONENT', 'BACK'),
    LOGIN_REDIRECT_TEXT: translation('BQUI', undefined, 'SIGN_UP_COMPONENT', 'LOGIN_REDIRECT_TEXT'),
    LOGIN_REDIRECT_LINK_TEXT: translation('BQUI', undefined, 'SIGN_UP_COMPONENT', 'LOGIN_REDIRECT_LINK_TEXT'),
    OR_DASH: translation('BQUI', undefined, 'SIGN_UP_COMPONENT', 'OR_DASH'),
  },
  BUY_PACKAGE_COMPONENT: {
    HEADER: translation('BQUI', undefined, 'BUY_PACKAGE_COMPONENT', 'HEADER'),
    TRIAL_PACKAGE_HEADER: translation('BQUI', undefined, 'BUY_PACKAGE_COMPONENT', 'TRIAL_PACKAGE_HEADER'),
    TRIAL_PACKAGE_DESCRIPTION: translation('BQUI', undefined, 'BUY_PACKAGE_COMPONENT', 'TRIAL_PACKAGE_DESCRIPTION'),
    TRIAL_PACKAGE_PER_ANALYSIS: translation('BQUI', undefined, 'BUY_PACKAGE_COMPONENT', 'TRIAL_PACKAGE_PER_ANALYSIS'),
    BUY_NOW: translation('BQUI', undefined, 'BUY_PACKAGE_COMPONENT', 'BUY_NOW'),
    BUY_NOW_FOR_PRICE: translation('BQUI', undefined, 'BUY_PACKAGE_COMPONENT', 'BUY_NOW_FOR_PRICE'),
    ANALYSIS: translation('BQUI', undefined, 'BUY_PACKAGE_COMPONENT', 'ANALYSIS'),
    ANALYSES: translation('BQUI', undefined, 'BUY_PACKAGE_COMPONENT', 'ANALYSES'),
    FREE_ANALYSIS: translation('BQUI', undefined, 'BUY_PACKAGE_COMPONENT', 'FREE_ANALYSIS'),
    FREE_ANALYSES: translation('BQUI', undefined, 'BUY_PACKAGE_COMPONENT', 'FREE_ANALYSES'),
    PER_ANALYSIS: translation('BQUI', undefined, 'BUY_PACKAGE_COMPONENT', 'PER_ANALYSIS'),
    HIGHLIGHTED_PACKAGE_LABEL: translation('BQUI', undefined, 'BUY_PACKAGE_COMPONENT', 'HIGHLIGHTED_PACKAGE_LABEL'),
    TOGGLE_PAYGO: translation('BQUI', undefined, 'BUY_PACKAGE_COMPONENT', 'TOGGLE_PAYGO'),
    TOGGLE_ENTERPRISE: translation('BQUI', undefined, 'BUY_PACKAGE_COMPONENT', 'TOGGLE_ENTERPRISE'),
    ENTERPRISE_PACKAGE_HEADER: translation('BQUI', undefined, 'BUY_PACKAGE_COMPONENT', 'ENTERPRISE_PACKAGE_HEADER'),
    ENTERPRISE_PACKAGE_PRICE: translation('BQUI', undefined, 'BUY_PACKAGE_COMPONENT', 'ENTERPRISE_PACKAGE_PRICE'),
    ENTERPRISE_PACKAGE_PRICE_PRETEXT: translation(
      'BQUI',
      undefined,
      'BUY_PACKAGE_COMPONENT',
      'ENTERPRISE_PACKAGE_PRICE_PRETEXT'
    ),
    ENTERPRISE_PACKAGE_PRICE_POSTTEXT: translation(
      'BQUI',
      undefined,
      'BUY_PACKAGE_COMPONENT',
      'ENTERPRISE_PACKAGE_PRICE_POSTTEXT'
    ),
    ENTERPRISE_PACKAGE_ACTION_BUTTON: translation(
      'BQUI',
      undefined,
      'BUY_PACKAGE_COMPONENT',
      'ENTERPRISE_PACKAGE_ACTION_BUTTON'
    ),
    ENTERPRISE_PACKAGE_DESCRIPTION: translation(
      'BQUI',
      undefined,
      'BUY_PACKAGE_COMPONENT',
      'ENTERPRISE_PACKAGE_DESCRIPTION'
    ),
    ENTERPRISE_PACKAGE_ACTION_BUTTON_EMAIL_ADDRESS: translation(
      'BQUI',
      undefined,
      'BUY_PACKAGE_COMPONENT',
      'ENTERPRISE_PACKAGE_ACTION_BUTTON_EMAIL_ADDRESS'
    ),
    ENTERPRISE_PACKAGE_ACTION_BUTTON_EMAIL_SUBJECT: translation(
      'BQUI',
      undefined,
      'BUY_PACKAGE_COMPONENT',
      'ENTERPRISE_PACKAGE_ACTION_BUTTON_EMAIL_SUBJECT'
    ),
    ENTERPRISE_PACKAGE_ACTION_BUTTON_EMAIL_CONTENT: translation(
      'BQUI',
      undefined,
      'BUY_PACKAGE_COMPONENT',
      'ENTERPRISE_PACKAGE_ACTION_BUTTON_EMAIL_CONTENT'
    ),
  },
  API_MANAGEMENT: {
    LINK_OPEN_DOCUMENTATION: translation('BQUI', undefined, 'API_MANAGEMENT', 'BUTTON_OPEN_DOCUMENTATION'),
    CONTAINER_TITLE: translation('BQUI', undefined, 'API_MANAGEMENT', 'CONTAINER_TITLE'),
    CREATE_CREDENTIALS_SUCCESS_MESSAGE: translation(
      'BQUI',
      undefined,
      'API_MANAGEMENT',
      'CREATE_CREDENTIALS_SUCCESS_MESSAGE'
    ),
    CREATE_CREDENTIALS_ERROR_MESSAGE: translation(
      'BQUI',
      undefined,
      'API_MANAGEMENT',
      'CREATE_CREDENTIALS_ERROR_MESSAGE'
    ),
    CREATE_CREDENTIALS_FORM_DESCRIPTION_PLACEHOLDER: translation(
      'BQUI',
      undefined,
      'API_MANAGEMENT',
      'CREATE_CREDENTIALS_FORM_DESCRIPTION_PLACEHOLDER'
    ),
    CREATE_CREDENTIALS_FORM_SUCCESS_MESSAGE: translation(
      'BQUI',
      undefined,
      'API_MANAGEMENT',
      'CREATE_CREDENTIALS_FORM_SUCCESS_MESSAGE'
    ),
    CREATE_CREDENTIALS_HEADER: translation('BQUI', undefined, 'API_MANAGEMENT', 'CREATE_CREDENTIALS_HEADER'),
    REVOKE_CREDENTIALS_SUCCESS_MESSAGE: translation(
      'BQUI',
      undefined,
      'API_MANAGEMENT',
      'REVOKE_CREDENTIALS_SUCCESS_MESSAGE'
    ),
    REVOKE_CREDENTIALS_ERROR_MESSAGE: translation(
      'BQUI',
      undefined,
      'API_MANAGEMENT',
      'REVOKE_CREDENTIALS_ERROR_MESSAGE'
    ),
    TABLE_HEADER_ACCOUNT: translation('BQUI', undefined, 'API_MANAGEMENT', 'TABLE_HEADER_ACCOUNT'),
    TABLE_HEADER_USER: translation('BQUI', undefined, 'API_MANAGEMENT', 'TABLE_HEADER_USER'),
    TABLE_HEADER_DESCRIPTION: translation('BQUI', undefined, 'API_MANAGEMENT', 'TABLE_HEADER_DESCRIPTION'),
    TABLE_HEADER_VALID_FROM: translation('BQUI', undefined, 'API_MANAGEMENT', 'TABLE_HEADER_VALID_FROM'),
  },
  BQA_RECOMMENDATIONS: {
    FILENAME: translation('BQUI', undefined, 'BQA_RECOMMENDATIONS', 'FILENAME'),
  },
  DATA_EXPORT: {
    VIEW_SELECT_PRODUCT_PLACEHOLDER_TEXT: translation(
      'BQUI',
      undefined,
      'DATA_EXPORT',
      'VIEW_SELECT_PRODUCT_PLACEHOLDER_TEXT'
    ),
    VIEW_SELECT_LANGUAGE_PLACEHOLDER_TEXT: translation(
      'BQUI',
      undefined,
      'DATA_EXPORT',
      'VIEW_SELECT_LANGUAGE_PLACEHOLDER_TEXT'
    ),
    VIEW_SELECT_LANGUAGE_VALIDATION_ERROR: translation(
      'BQUI',
      undefined,
      'DATA_EXPORT',
      'VIEW_SELECT_LANGUAGE_VALIDATION_ERROR'
    ),
    VIEW_SELECT_PRODUCT_VALIDATION_ERROR: translation(
      'BQUI',
      undefined,
      'DATA_EXPORT',
      'VIEW_SELECT_PRODUCT_VALIDATION_ERROR'
    ),
    VIEW_BUTTON_EXPORT_TEXT: translation('BQUI', undefined, 'DATA_EXPORT', 'VIEW_BUTTON_EXPORT_TEXT'),
    VIEW_NOTIFICATION_DOWNLOAD_ERROR: translation('BQUI', undefined, 'DATA_EXPORT', 'VIEW_NOTIFICATION_DOWNLOAD_ERROR'),
    VIEW_DOWNLOAD_NOTIFICATION_SUCCESS: translation(
      'BQUI',
      undefined,
      'DATA_EXPORT',
      'VIEW_DOWNLOAD_NOTIFICATION_SUCCESS'
    ),
  },
  USER_SETTINGS: {
    CONTAINER_TITLE: translation('BQUI', undefined, 'USER_SETTINGS', 'CONTAINER_TITLE'),
    NAV_MENU_LANGUAGE: translation('BQUI', undefined, 'USER_SETTINGS', 'NAV_MENU_LANGUAGE'),
    NAV_MENU_PASSWORD: translation('BQUI', undefined, 'USER_SETTINGS', 'NAV_MENU_PASSWORD'),
    LANGUAGE_CONTAINER_TITLE_TEXT: translation('BQUI', undefined, 'USER_SETTINGS', 'LANGUAGE_CONTAINER_TITLE_TEXT'),
    LANGUAGE_UPDATE_SUCCESS_MESSAGE: translation('BQUI', undefined, 'USER_SETTINGS', 'LANGUAGE_UPDATE_SUCCESS_MESSAGE'),
    LANGUAGE_UPDATE_ERROR_MESSAGE: translation('BQUI', undefined, 'USER_SETTINGS', 'LANGUAGE_UPDATE_ERROR_MESSAGE'),
    LANGUAGE_COMPONENT_SELECT_PLACEHOLDER: translation(
      'BQUI',
      undefined,
      'USER_SETTINGS',
      'LANGUAGE_COMPONENT_SELECT_PLACEHOLDER'
    ),
    LANGUAGE_BUTTON_CONFIRM: translation('BQUI', undefined, 'USER_SETTINGS', 'LANGUAGE_BUTTON_CONFIRM'),
    PASSWORD_CONTAINER_TITLE_TEXT: translation('BQUI', undefined, 'USER_SETTINGS', 'PASSWORD_CONTAINER_TITLE_TEXT'),
    PASSWORD_CHANGE_SUCCESS_MESSAGE: translation('BQUI', undefined, 'USER_SETTINGS', 'PASSWORD_CHANGE_SUCCESS_MESSAGE'),
    PASSWORD_CHANGE_ERROR_MESSAGE: translation('BQUI', undefined, 'USER_SETTINGS', 'PASSWORD_CHANGE_ERROR_MESSAGE'),
    CURRENT_PASSWORD_PLACEHOLDER: translation('BQUI', undefined, 'USER_SETTINGS', 'CURRENT_PASSWORD_PLACEHOLDER'),
    NEW_PASSWORD_PLACEHOLDER: translation('BQUI', undefined, 'USER_SETTINGS', 'NEW_PASSWORD_PLACEHOLDER'),
    PASSWORD_CONFIRMATION_PLACEHOLDER: translation(
      'BQUI',
      undefined,
      'USER_SETTINGS',
      'PASSWORD_CONFIRMATION_PLACEHOLDER'
    ),
    PASSWORD_HINT_LENGTH: translation('BQUI', undefined, 'USER_SETTINGS', 'PASSWORD_HINT_LENGTH'),
    PASSWORD_HINT_UPPER_CASE: translation('BQUI', undefined, 'USER_SETTINGS', 'PASSWORD_HINT_UPPER_CASE'),
    PASSWORD_HINT_LOWER_CASE: translation('BQUI', undefined, 'USER_SETTINGS', 'PASSWORD_HINT_LOWER_CASE'),
    PASSWORD_HINT_DIGIT: translation('BQUI', undefined, 'USER_SETTINGS', 'PASSWORD_HINT_DIGIT'),
    PASSWORD_HINT_SYMBOL: translation('BQUI', undefined, 'USER_SETTINGS', 'PASSWORD_HINT_SYMBOL'),
    PRIVACY_POLICY_DATA_FORM_TITLE_TEXT: translation(
      'BQUI',
      undefined,
      'USER_SETTINGS',
      'PRIVACY_POLICY_DATA_FORM_TITLE_TEXT'
    ),
    PRIVACY_POLICY_DATA_FALLBACK_TEXT: translation(
      'BQUI',
      undefined,
      'USER_SETTINGS',
      'PRIVACY_POLICY_DATA_FALLBACK_TEXT'
    ),
    PRIVACY_POLICY_DATA_EXAMPLE_TEXT: translation(
      'BQUI',
      undefined,
      'USER_SETTINGS',
      'PRIVACY_POLICY_DATA_EXAMPLE_TEXT'
    ),
    PRIVACY_POLICY_BUTTON_EDIT: translation('BQUI', undefined, 'USER_SETTINGS', 'PRIVACY_POLICY_BUTTON_EDIT'),
    PRIVACY_POLICY_BUTTON_CONFIRM: translation('BQUI', undefined, 'USER_SETTINGS', 'PRIVACY_POLICY_BUTTON_CONFIRM'),
    PRIVACY_POLICY_UPDATE_SUCCESS_MESSAGE: translation(
      'BQUI',
      undefined,
      'USER_SETTINGS',
      'PRIVACY_POLICY_UPDATE_SUCCESS_MESSAGE'
    ),
    PRIVACY_POLICY_UPDATE_ERROR_MESSAGE: translation(
      'BQUI',
      undefined,
      'USER_SETTINGS',
      'PRIVACY_POLICY_UPDATE_ERROR_MESSAGE'
    ),
    API_MGMT_CONTAINER_TITLE_TEXT: translation('BQUI', undefined, 'USER_SETTINGS', 'API_MGMT_CONTAINER_TITLE_TEXT'),
  },
  COMPONENT_PASSWORD_VALIDATION: {
    INPUT_NEW_PASSWORD_PLACEHOLDER: translation(
      'BQUI',
      undefined,
      'COMPONENT_PASSWORD_VALIDATION',
      'INPUT_NEW_PASSWORD_PLACEHOLDER'
    ),
    INPUT_CONFIRM_NEW_PASSWORD_PLACEHOLDER: translation(
      'BQUI',
      undefined,
      'COMPONENT_PASSWORD_VALIDATION',
      'INPUT_CONFIRM_NEW_PASSWORD_PLACEHOLDER'
    ),
    VALIDATION_ERROR_PASSWORDS_NOT_MATCHING: translation(
      'BQUI',
      undefined,
      'COMPONENT_PASSWORD_VALIDATION',
      'VALIDATION_ERROR_PASSWORDS_NOT_MATCHING'
    ),
    VALIDATION_ERROR_MIN_STRENGTH: translation(
      'BQUI',
      undefined,
      'COMPONENT_PASSWORD_VALIDATION',
      'VALIDATION_ERROR_MIN_STRENGTH'
    ),
    VALIDATION_ERROR_MIN_LENGTH: translation(
      'BQUI',
      undefined,
      'COMPONENT_PASSWORD_VALIDATION',
      'VALIDATION_ERROR_MIN_LENGTH'
    ),
    VALIDATION_ERROR_CURRENT_PASSWORD_REQUIRED: translation(
      'BQUI',
      undefined,
      'COMPONENT_PASSWORD_VALIDATION',
      'VALIDATION_ERROR_CURRENT_PASSWORD_REQUIRED'
    ),
  },
  COMPONENT_EMAIL_VALIDATION: {
    VALIDATION_ERROR_INVALID_EMAIL: translation(
      'BQUI',
      undefined,
      'COMPONENT_EMAIL_VALIDATION',
      'VALIDATION_ERROR_INVALID_EMAIL'
    ),
  },
  COMPONENT_PASSWORD_RECOVERY: {
    TITLE: translation('BQUI', undefined, 'COMPONENT_PASSWORD_RECOVERY', 'TITLE'),
    DESCRIPTION: translation('BQUI', undefined, 'COMPONENT_PASSWORD_RECOVERY', 'DESCRIPTION'),
    EMAIL_PLACEHOLDER: translation('BQUI', undefined, 'COMPONENT_PASSWORD_RECOVERY', 'EMAIL_PLACEHOLDER'),
    CONFIRM: translation('BQUI', undefined, 'COMPONENT_PASSWORD_RECOVERY', 'CONFIRM'),
    BACK_TO_LOGIN: translation('BQUI', undefined, 'COMPONENT_PASSWORD_RECOVERY', 'BACK_TO_LOGIN'),
    LINK_SENT: translation('BQUI', undefined, 'COMPONENT_PASSWORD_RECOVERY', 'LINK_SENT'),
    ERROR: translation('BQUI', undefined, 'COMPONENT_PASSWORD_RECOVERY', 'ERROR'),
    TRY_AGAIN: translation('BQUI', undefined, 'COMPONENT_PASSWORD_RECOVERY', 'TRY_AGAIN'),
  },
  COMPONENT_PASSWORD_RESET: {
    TITLE: translation('BQUI', undefined, 'COMPONENT_PASSWORD_RESET', 'TITLE'),
    DESCRIPTION: translation('BQUI', undefined, 'COMPONENT_PASSWORD_RESET', 'DESCRIPTION'),
    CONFIRM: translation('BQUI', undefined, 'COMPONENT_PASSWORD_RESET', 'CONFIRM'),
    LINK_EXPIRED: translation('BQUI', undefined, 'COMPONENT_PASSWORD_RESET', 'LINK_EXPIRED'),
    REQUEST_NEW_LINK: translation('BQUI', undefined, 'COMPONENT_PASSWORD_RESET', 'REQUEST_NEW_LINK'),
    SUCCESS: translation('BQUI', undefined, 'COMPONENT_PASSWORD_RESET', 'SUCCESS'),
    BACK_TO_LOGIN: translation('BQUI', undefined, 'COMPONENT_PASSWORD_RESET', 'BACK_TO_LOGIN'),
    ERROR: translation('BQUI', undefined, 'COMPONENT_PASSWORD_RESET', 'ERROR'),
    TRY_AGAIN: translation('BQUI', undefined, 'COMPONENT_PASSWORD_RESET', 'TRY_AGAIN'),
  },
  MENU_NAV: {
    SWITCH_TO: translation('BQUI', undefined, 'MENU_NAV', 'SWITCH_TO'),
    OTHER_PRODUCTS: translation('BQUI', undefined, 'MENU_NAV', 'OTHER_PRODUCTS'),
    FURTHER_INFO: translation('BQUI', undefined, 'MENU_NAV', 'FURTHER_INFO'),
    NEWS: translation('BQUI', undefined, 'MENU_NAV', 'NEWS'),
    CONTACT: translation('BQUI', undefined, 'MENU_NAV', 'CONTACT'),
    NEWS_EXTERNAL_LINK: translation('BQUI', undefined, 'MENU_NAV', 'NEWS_EXTERNAL_LINK'),
    CONTACT_MAILTO_ADDRESS: translation('BQUI', undefined, 'MENU_NAV', 'CONTACT_MAILTO_ADDRESS'),
    FREE_TRIAL_AVAILABLE: translation('BQUI', undefined, 'MENU_NAV', 'FREE_TRIAL_AVAILABLE'),
    SELECT_PRODUCT_PLACEHOLDER_TEXT: translation('BQUI', undefined, 'MENU_NAV', 'SELECT_PRODUCT_PLACEHOLDER_TEXT'),
    PRODUCT_BQA_RESULTS: translation('BQUI', undefined, 'MENU_NAV', 'PRODUCT_BQA_RESULTS'),
    PRODUCT_BQP_RESULTS: translation('BQUI', undefined, 'MENU_NAV', 'PRODUCT_BQP_RESULTS'),
    PRODUCT_BQR_RESULTS: translation('BQUI', undefined, 'MENU_NAV', 'PRODUCT_BQR_RESULTS'),
    CURRENT_BALANCE_LABEL: translation('BQUI', undefined, 'MENU_NAV', 'CURRENT_BALANCE_LABEL'),
    CURRENT_BALANCE_CREDITS: translation('BQUI', undefined, 'MENU_NAV', 'CURRENT_BALANCE_CREDITS'),
    LOGGED_IN_AS: translation('BQUI', undefined, 'MENU_NAV', 'LOGGED_IN_AS'),
    ANONYMIZE_BUTTON_TEXT: translation('BQUI', undefined, 'MENU_NAV', 'ANONYMIZE_BUTTON_TEXT'),
    USER_ANONYMIZATION_BUTTON_TEXT: translation('BQUI', undefined, 'MENU_NAV', 'USER_ANONYMIZATION_BUTTON_TEXT'),
    DATA_EXPORT_BUTTON_TEXT: translation('BQUI', undefined, 'MENU_NAV', 'DATA_EXPORT_BUTTON_TEXT'),
    USER_SETTINGS_BUTTON_TEXT: translation('BQUI', undefined, 'MENU_NAV', 'USER_SETTINGS_BUTTON_TEXT'),
    USER_LOGOUT_BUTTON_TEXT: translation('BQUI', undefined, 'MENU_NAV', 'USER_LOGOUT_BUTTON_TEXT'),
    CONFIRMATION_INFO_TEXT: translation('BQUI', undefined, 'MENU_NAV', 'CONFIRMATION_INFO_TEXT'),
    CONFIRMATION_RESEND_LINK_TEXT: translation('BQUI', undefined, 'MENU_NAV', 'CONFIRMATION_RESEND_LINK_TEXT'),
    BUY_CREDITS_TEXT: translation('BQUI', undefined, 'MENU_NAV', 'BUY_CREDITS_TEXT'),
    BUY_CREDITS_TOOLTIP: translation('BQUI', undefined, 'MENU_NAV', 'BUY_CREDITS_TOOLTIP'),
    USER_SETTINGS_TITLE: translation('BQUI', undefined, 'MENU_NAV', 'USER_SETTINGS_TITLE'),
  },
  EVUI_GUIDE: {
    BUTTON_NEXT: translation('BQUI', undefined, 'EVUI_GUIDE', 'BUTTON_NEXT'),
    BUTTON_BACK: translation('BQUI', undefined, 'EVUI_GUIDE', 'BUTTON_BACK'),
    BUTTON_FINISH: translation('BQUI', undefined, 'EVUI_GUIDE', 'BUTTON_FINISH'),
  },
  BETA_COMPONENT: {
    TITLE: translation('BQUI', undefined, 'BETA_COMPONENT', 'TITLE'),
    CONTENT: translation('BQUI', undefined, 'BETA_COMPONENT', 'CONTENT'),
    LINK: translation('BQUI', undefined, 'BETA_COMPONENT', 'LINK'),
  },
  PUBLIC_PAGE: {
    LOGIN_BUTTON: translation('BQUI', undefined, 'PUBLIC_PAGE', 'LOGIN_BUTTON'),
    START_BUTTON: translation('BQUI', undefined, 'PUBLIC_PAGE', 'START_BUTTON'),
    START_BUTTON_LINK: translation('BQUI', undefined, 'PUBLIC_PAGE', 'START_BUTTON_LINK'),
  },
};

const BQUI = {
  PRODUCT_BQP: BQUI_BASE_PRODUCT('PRODUCT_BQP'),
  PRODUCT_BQA: BQUI_BASE_PRODUCT('PRODUCT_BQA'),
  PRODUCT_BQR: BQUI_BASE_PRODUCT('PRODUCT_BQR'),
  PRODUCT_BQP_SELF_ASSESSMENT: BQUI_BASE_PRODUCT('PRODUCT_BQP_SELF_ASSESSMENT'),
  ...BQUI_BASE_COMMON,
};

export const BQTranslationPlugin = {
  install(Vue) {
    Vue.prototype.$BQUI = BQUI;
  },
};

export default i18n;
